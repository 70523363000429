export default {
    isUserLoggedIn(state) {
        return state.logInStatus;
    },
    getLoginResponse(state) {
        return state.loginResponse;
    },
    getSignUpResponse(state) {
        return state.signUpResponse;
    },
    getJWTToken(state) {
        return state.JWTToken;
    },
    getUserData(state) {
        return state.userData;
    },
    getChangePasswordStatus(state) {
        return state.changePasswordStatus
    },
    getPasswordRecoveryStatus(state) {
        return state.passwordRecoveryStatus
    },
    getActivateEmailRequestStatus(state) {
        return state.activateEmailRequestStatus
    },
    getEmailVerifyStatus(state) {
        return state.emailVerifyStatus
    }
}
