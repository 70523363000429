<template>
  <v-scale-transition>
    <div v-show="show">
      <v-row align="center" justify="center" align-content="center" class="my-5">
        <span v-if="mode ==='RESET' || mode === 'CHANGE'" class="text-h5 font-weight-bold text-center">Introdu-ti noua parola mai jos:</span>
        <span v-else
              class="text-h5 font-weight-bold text-center">Introdu mail-ul pentru care vrei sa resetezi parola:</span>
      </v-row>
      <v-row align="center" justify="center" align-content="center">
        <v-col cols="12" sm="8" md="5">
          <v-card>
            <v-card-text>
              <v-form :disabled="loading" v-model="valid">
                <v-text-field
                    v-if="mode ==='RECOVERY' "
                    label="Email"
                    name="Email"
                    prepend-icon="mdi-email"
                    type="text"
                    color="primary accent-3"
                    :rules="emailRules"
                    v-model="email"
                />

                <v-text-field
                    v-if="mode ==='RESET' || mode === 'CHANGE'"
                    id="password"
                    label="Parola Noua"
                    name="Parola Noua"
                    prepend-icon="mdi-lock"
                    :type="!appendClicked ? 'password': 'text'"
                    color="primary accent-3"
                    :rules="passwordRules"
                    v-model="password"
                    :append-icon="!appendClicked ? 'mdi-eye-off' :'mdi-eye'"
                    @click:append="appendClicked = !appendClicked"
                ></v-text-field>

              </v-form>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn large
                     color="primary"
                     rounded
                     :loading="loading"
                     :disabled="loading || !valid"
                     @click="resetPressed">Resetare Parola
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-scale-transition>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PasswordReset',
  props: {
    mode: {
      type: String,
      required: false
    }
  },
  components: {},
  data() {
    return {
      valid: false,
      show: false,
      passwordRules: [
        (password) => !(password.length > 30 || password.length < 8) === true || 'Parola trebuie sa aiba intre 8 si 30 de caractere.',
        (password) => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})").test(password) === true || 'Parola trebuie sa includa atat caractere mici cat si mari dar si simboluri precum !@#$%^&*'
      ],
      emailRules: [
        (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase()) === true || 'Email-ul introdus nu este valid.',
      ],
      password: "",
      email: "",
      appendClicked: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword', 'changePassword', 'recoveryPassword']),
    resetPressed() {
      this.loading = true
      if (this.mode === 'RESET') {
        let path = this.$route.fullPath
        this.resetPassword({password: this.password, resetCode: path.substring(path.lastIndexOf('/') + 1)})
      } else if (this.mode === 'CHANGE') {
        this.changePassword(this.password)
      } else if (this.mode === 'RECOVERY') {
        this.recoveryPassword(this.email)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getChangePasswordStatus', 'getPasswordRecoveryStatus'])
  },
  mounted() {
    this.show = true
  },
  watch: {
    getChangePasswordStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"})
        if (this.mode !== 'CHANGE')
          this.$router.push('/sign-up')
        else this.$router.push('/profil')

      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"})

      this.loading = false
    },
    getPasswordRecoveryStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"})
        this.$router.push('/sign-up')

      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"})

      this.loading = false
    }
  }
}
</script>
