<template>
  <v-scale-transition>
    <v-row class="mx-auto" justify="space-around" v-show="show">
      <v-col cols="12" md="5">
        <UserProfile :elevation="elevation" :loading="loading" :name="getUserData.name"
                     :email="getUserData.email"
                     :profilePicture="getUserData.imageUrl" :role="getUserData.maxAuthorizationType"/>
      </v-col>
      <v-col cols="12" md="7">
        <UserData :elevation="elevation" :loading="loading" :name="getUserData.name"
                  :email="getUserData.email"
                  :registrationDate="getUserData.registrationDate" :email-verified="getUserData.emailVerified"
                  :expiration-time="getUserData.expirationTime"></UserData>
      </v-col>
    </v-row>
  </v-scale-transition>
</template>


<script>


import {mapActions, mapGetters} from "vuex";
import UserProfile from "../components/UserProfile";
import UserData from "../components/UserData";

export default {
  name: 'Profil',
  components: {UserProfile, UserData},
  data() {
    return {
      loading: false,
      elevation: 15,
      show: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData'])
  },
  async created() {
    this.loading = true
    await this.retrieveUserData()
    this.loading = false
  },
  methods: {
    ...mapActions('auth', ['retrieveUserData']),
  },
  mounted() {
    this.show = true
  }
}
</script>
