<template>
  <div>
    <v-row justify="center" class="my-1" v-if="alreadyFinished">
      <v-col cols="12" class="text-center">
        <v-btn color="primary" rounded :to="$route.query.root ? $route.query.root : '/chestionare'">
          <v-icon left>fa-chevron-left</v-icon>
          Intoarce-te la chestionare
        </v-btn>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-progress-linear
            color="light-blue darken-3"
            height="10"
            :value="(index + 1) * 100 / wrongQuestions.length"
            striped
            v-bind="attrs"
            v-on="on"
        ></v-progress-linear>
      </template>
      <span>Progresul tau</span>
    </v-tooltip>

    <v-row>
      <v-col cols="12">
        <Questionnaire class="my-3" :wrong-question-mode="true" :question="wrongQuestions[index].fullQuestion.question"
                       :disabled="true" @send="nextQuestion" :visible-explanation="true"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AskQuestion
            :question-id="wrongQuestions[index].fullQuestion.question ? wrongQuestions[index].fullQuestion.question.id : -1"
            v-if="!isQuestionFeatureDisabled"/>
      </v-col>
    </v-row>


  </div>

</template>


<script>

import Questionnaire from "./Questionnaire";
import AskQuestion from "../Questions/AskQuestion";
import {mapGetters} from "vuex";

export default {
  name: 'WrongQuestionsDisplay',
  components: {Questionnaire, AskQuestion},
  props: {
    wrongQuestions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      index: 0,
      alreadyFinished: false
    }
  },
  computed: {
    ...mapGetters('app', ['isQuestionFeatureDisabled'])
  },
  methods: {
    nextQuestion() {
      //loop trough questions
      if (this.index + 1 < this.wrongQuestions.length)
        this.index++;
      else {
        this.$toast.warning('Ai parcurs deja toate intrebarile gresite!', '', {position: "topCenter"})
        this.alreadyFinished = true
        this.index = 0
      }
    }
  },
  watch: {}
}
</script>
