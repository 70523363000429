<template>
  <div>
    <v-card class="ma-0 pa-0" flat rounded :color="!chapterData ? '' : 'transparent'">
      <v-card-title v-if="!chapterData">
        Codul Rutier Explicat
      </v-card-title>

      <v-card-text class="subtitle-1" v-if="!chapterData">
        {{ description }}
      </v-card-text>
      <v-card-text class="justify-center ma-0 pa-0">
        <v-row justify="center">
          <div>
            <v-img contain v-if="!chapterData" class="clickable"
                   src='@/assets/legislation/choose_chapter_small.png'
                   lazy-src='@/assets/legislation/choose_chapter_small.png'
                   @click="dialog = true" :max-width="imageSize"></v-img>
          </div>
        </v-row>
      </v-card-text>
      <v-row justify="center">
        <v-col cols="12" class="justify-center">
          <v-dialog v-model="dialog" max-width="1200px">
            <legislation-chapter-chooser :chapters="legislationChapters === null ? []: legislationChapters"
                                         @new-chapter="newChapter"/>
          </v-dialog>
        </v-col>

        <v-col :cols="breakpointName === 'xs'? 12 : 8" v-if="chapterData">
          <v-card elevation="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-linear
                    color="light-blue darken-3"
                    height="10"
                    :value=" pageCount && currentPage ?  currentPage * 100 / pageCount  : 0"
                    striped
                    v-bind="attrs"
                    v-on="on"
                    v-show="loading === false && pageCount !== 0"
                ></v-progress-linear>
              </template>
              <span>Progresul tau in acest capitol.</span>
            </v-tooltip>

            <pdf
                :src="'data:application/pdf;base64,'+chapterData"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                style="width: 100%; height: 100%"
                :page="page"
                @loaded="loading = false"
                v-show="loading === false && pageCount !== 0"
            >
            </pdf>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!--    Loading Overlay   -->
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="80"
          color="amber"
          :width="5"
      ></v-progress-circular>
    </v-overlay>

    <!--    Footer buttons for navigation (next,previous,chapter chooser) -->
    <legislation-navigation @choose="dialog = true" @next="next" @previous="previous"
                            :display-nav="pageCount !== 0"></legislation-navigation>
  </div>
</template>


<script>

import pdf from 'vue-pdf'
import {mapActions, mapGetters} from "vuex";
import LegislationChapterChooser from "../components/Legislation/LegislationChapterChooser";
import LegislationNavigation from "../components/Legislation/LegislationNavigation";

export default {
  name: 'Legislatie',
  components: {
    LegislationNavigation,
    LegislationChapterChooser,
    pdf
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      page: 1,
      dialog: false,
      chapterData: null,
      loading: false,
      pdfKey: 0,
      loadedProgress: 0,
      description: "Sunt doar informațiile necesare pentru examen.\n" +
          "        Este scurt, ușor de citit și de înțeles.\n" +
          "        Citiți-l o singură dată, înainte de întrebări!\n" +
          "        Vă ajută să învățați mult mai ușor întrebările."
    }
  },
  methods: {
    ...mapActions('app', ['getLegislationChapters', 'getLegislationChapter']),
    //GET the chapter data and assign it to the current data
    async newChapter(chapterInfo) {
      this.dialog = false

      if (!this.isUserLoggedIn) {
        this.$toast.warning("Trebuie sa fii logat pentru a accesa legislatia!", '', {position: "topCenter"})
        await this.$router.push("/sign-up")
        return
      }

      if (!chapterInfo.allowed) {
        await this.$router.push('/premium')
        this.$toast.info("Aceast capitol este disponibil doar utilizatorilor premium!", 'Premium', {position: "topCenter"})
        return
      }

      this.loading = true
      this.page = 1;

      if (!this.legislationChapter(chapterInfo))
        await this.getLegislationChapter(chapterInfo)


      if (this.legislationChapter(chapterInfo) === null)
        this.$toast.error("Serverul intampina dificulati! Incearca din nou mai tarziu sau contacteaza echipa de suport!", '', {position: "topCenter"})

      if (this.chapterData !== this.legislationChapter(chapterInfo))
        this.chapterData = this.legislationChapter(chapterInfo)
      else this.loading = false
    },
    next() {
      if (this.page < this.pageCount) {
        this.page++
        window.scrollTo({top: 0, behavior: 'smooth'})
      } else if (this.pageCount !== 0)
        this.$toast.warning("Ai terminat acest capitol!", '', {position: "topCenter"})

    },
    previous() {
      if (this.page > 1) {
        this.page--
        window.scrollTo({top: 0, behavior: 'smooth'})
      } else if (this.pageCount !== 0)
        this.$toast.warning("Aceasta este prima pagina din capitol!", '', {position: "topCenter"})
    }
  },
  mounted() {
    if (!this.legislationChapters) {
      this.getLegislationChapters()
    }
  },
  computed: {
    ...mapGetters('app', ['legislationChapters', 'legislationChapter']),
    ...mapGetters('auth', ['isUserLoggedIn']),
    breakpointName() {
      return this.$vuetify.breakpoint.name
    },
    imageSize() {
      if (this.breakpointName === 'xs') {
        if (window.innerWidth < 300)
          return 280

        return 300
      }

      return 500
    }
  }
}
</script>

<style scoped lang="sass">

.v-card-title
  border: solid black

.clickable
  cursor: pointer !important


</style>
