export default {
    SET_QUESTIONS: (state, questions) => {
        state['questions'] = questions
    },
    SET_QUESTIONS_STATUS: (state, status) => {
        state['questionsReceivedStatus'] = status
    },
    SET_ADD_QUESTION_STATUS: (state, status) => {
        state['addQuestionStatus'] = status
    },
    SET_EDIT_QUESTION_STATUS: (state, status) => {
        state['editQuestionStatus'] = status
    },
    SET_DELETE_QUESTION_STATUS: (state, status) => {
        state['deleteQuestionStatus'] = status
    },
    SET_ADD_QUESTIONNAIRE_STATUS: (state,questionnaires) => {
        state['addQuestionnaireStatus'] = questionnaires
    },
    SET_EDIT_QUESTIONNAIRE_STATUS: (state,status) => {
        state['editQuestionnaireStatus'] = status
    },
    SET_ADD_QUESTION_INTO_QUESTIONNAIRE_STATUS: (state,status) => {
        state['addQuestionIntoQuestionnaireStatus'] = status
    },
    SET_DELETE_QUESTION_FROM_QUESTIONNAIRE_STATUS: (state,status) => {
        state['deleteQuestionFromQuestionnaireStatus'] = status
    },
    SET_DELETE_QUESTIONNAIRE_STATUS: (state,status) => {
        state['deleteQuestionnaireStatus'] = status
    }
}
