<template>
  <v-expand-transition>
    <v-card
        class="mx-auto d-flex flex-column shrink"
        color="white"
        dark
        max-width="450"
        rounded
        elevation="12"
        height="100%"
        v-show="expandAnimation"
    >
      <!--    Title With different color    -->
      <v-card-title :class="'darken-1 text-h4 justify-center ' + color">{{ title }}</v-card-title>
      <!--    Subtitle with main color -->
      <v-card-title :class="'justify-center '+color">
        <!--      <v-icon-->
        <!--          large-->
        <!--          left-->
        <!--      >-->
        <!--        mdi-cash-->
        <!--      </v-icon>-->

        <!--      Prices     -->
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-title align="center">
              <span class="text-h3 font-weight-bold">{{ price }} {{price === 1 ? 'Leu' : 'Lei'}} </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title align="center">
              <span class="subtitle-2 font-weight-bold">({{ priceSMS }} Euro SMS)</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title align="center">
              <span class="text-h6 font-weight-light">pentru {{ priceTime }} zile</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-title>


      <!--  feature list (e.g. access to explained questionnaires)  -->
      <v-card-text class="text-h5 white">
        <v-list color="transparent">
          <v-list-item v-for="(feature,index) in features" :key="index">
            <v-list-item-content class="black--text body-1">
            <span><v-icon class="mb-1 black--text" left>mdi-check-circle</v-icon>
            {{ feature }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-spacer></v-spacer>

      <!--    Buy  Button -->
      <v-card-actions class="white">
        <v-list-item class="grow">
          <v-row
              align="center"
              justify="center"
          >
            <v-btn rounded x-large min-width="200px" class="white--text" color="black" @click="$emit('buy-clicked',id)"
                   :loading="loading" light :disabled="disabled">
              {{
                !disabled ? "FREE_USER" === getUserData.maxAuthorizationType ? 'Alege' : 'Prelungeste Abonamentul' : 'Deja ai acest abonament'
              }}
            </v-btn>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-expand-transition>
</template>


<script>

import {mapGetters} from "vuex";
import {hasAuthority} from "../../utilities/AuthUtilities";

export default {
  name: 'PremiumCard',
  components: {},
  props: {
    id: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    priceSMS: {
      type: Number,
      required: true
    },
    priceTime: {
      type: String,
      required: true
    },
    features: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      expandAnimation: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData'])
  },
  methods: {
    hasAuthority,
    clickTest() {
    }
  },
  watch: {},
  mounted() {
    this.expandAnimation = true
  },
  beforeDestroy() {
  }
}
</script>
