<template>
  <div>
    <v-footer fixed app inset shaped color="transparent">
      <v-row class="text-center stats-bar-prop" justify="space-between"
             align-content="center">
        <v-col :cols="!displayNav ? 4 : breakpointName !== 'xs' ? 2 : 3" v-for="button in navButtons" :key="button.id">
          <v-card hover shaped @click="$emit(button.event)" v-show="displayNav || button.id ==='CHOOSE'">
            <v-card-actions class="justify-center">
              <v-icon v-if="button.position==='left'" :color="button.iconColor" size="50">
                {{ button.icon }}
              </v-icon>
              <v-card-text class="font-weight-bold ma-0 justify-center text-no-wrap"
                           v-if="breakpointName !== 'xs' && breakpointName !== 'sm'">
                {{ button.text }}
              </v-card-text>

              <v-icon v-if="button.position==='right'" :color="button.iconColor" size="50">
                {{ button.icon }}
              </v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>


export default {
  name: 'LegislationNavigation',
  components: {},
  props: {
    displayNav: Boolean
  },
  data() {
    return {
      navButtons: [
        {
          id: 'PREVIOUS',
          event: 'previous',
          text: 'Pagina Anterioara',
          icon: 'fa-arrow-left',
          iconColor: 'primary',
          position: 'left'

        },
        {
          id: 'CHOOSE',
          event: 'choose',
          text: 'Alege Capitolul',
          icon: 'fa-list-ul',
          iconColor: 'primary',
          position: 'left'

        },
        {
          id: 'NEXT',
          event: 'next',
          text: 'Pagina Urmatoare',
          icon: 'fa-arrow-right',
          iconColor: 'primary',
          position: 'right'

        }]
    }
  },
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name
    }
  }
  ,
  methods: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  beforeDestroy() {
  }
}
</script>

<style scoped lang="sass">


</style>
