<template>
  <div>
    <v-card :loading="loading" :elevation="elevation" flat >
      <v-row class="pa-3 py-5">
        <v-col cols="12" v-for="data in userData" :key="data.id">
          <div class="caption"> {{ data.text }}</div>
          <v-textarea v-if="data.data" :rules="nameRules" :value="data.data" auto-grow
                      hide-details :readonly="true" solo rows="1"
                      :class="`${data.id}`">
            <template slot="append">
              <v-tooltip v-if="data.id === 'EMAIL' && !emailVerified" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red"
                          @click="activateEmail"
                          v-bind="attrs"
                          v-on="on">{{ data.icon }}
                  </v-icon>
                </template>
                <span>Apasa aici pentru a-ti activa adresa de email.</span>
              </v-tooltip>
              <v-icon v-else>{{ data.icon }}</v-icon>
            </template>
          </v-textarea>
        </v-col>
      </v-row>

      <v-row justify="space-around" class="text-center">
        <v-col cols="8">
          <v-btn @click="changeUserName" rounded class="primary mx-auto my-auto" :to="'/cont/parola/schimba'">
            <v-icon :left="this.$vuetify.breakpoint.name !== 'xs'">mdi-lock</v-icon>
            <span v-if="this.$vuetify.breakpoint.name !== 'xs'"> Schimba-ti parola</span></v-btn>
        </v-col>
      </v-row>

      <v-row></v-row>
    </v-card>

  </div>
</template>

<script>
import {format, utcToZonedTime} from "date-fns-tz";
import parseISO from "date-fns/parseISO";
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'UserProfile',
  props: {
    name: String,
    email: String,
    role: String,
    registrationDate: String,
    loading: Boolean,
    elevation: Number,
    emailVerified: Boolean,
    expirationTime: String
  },
  data() {
    return {
      nameRules: [
        (name) => !(name.length > 50 || name.length < 1) === true || 'Numele trebuie sa aiba intre 1 si 50 de caractere.',
        (name) => /^[a-zA-Z0-9]+$/.test(name) === true || 'Numele nu contine doar litere si cifre.'
      ],
      notEditable: true
    }
  },
  methods: {
    ...mapActions('auth', ['requestEmailActivation', 'verifyEmail']),
    changeUserName() {
      this.enditable = !this.enditable
    },
    activateEmail() {
      this.requestEmailActivation()
    },
    getExpirationDate(time){
      let parsedTime = parseISO(time);
      const formatInTimeZone = (date, fmt, tz) =>
          format(utcToZonedTime(date, tz),
              fmt,
              { timeZone: tz });

      const formattedTime = formatInTimeZone(parsedTime, "dd-MM-yyyy kk:mm", "Europe/Bucharest");

      return formattedTime
    }
  },
  computed: {
    ...mapGetters('auth', ['getActivateEmailRequestStatus', 'getEmailVerifyStatus']),
    userData() {
      return [
        {
          id: "NUME",
          data: this.name,
          text: 'Nume Utilizator:',
          edit: false,
          icon: "mdi-account-box"
        },
        {
          id: "EMAIL",
          data: this.email,
          text: 'Email:',
          edit: false,
          icon: "mdi-email"
        },
        {
          id: "DATA",
          data: this.expirationTime ? this.getExpirationDate(this.expirationTime) : 'Abonamentul Premium nu este activ!',
          text: 'Data Explirarii Abonamentului Premium:',
          edit: false,
          icon: "mdi-calendar-range"
        }]
    }
  },
  watch: {
    getActivateEmailRequestStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"})
      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"})
    }
  }
}
</script>

<style lang="sass" scoped>

::v-deep v-btn
  font-size: 0.8em
  font-weight: 100

</style>
