<template>
  <div>
    <v-row>
      <v-col cols="12">
        <AdminActionChooser @question-edit="retrieveQuestions" @questionnaire-edit="retrieveQuestionnaire"/>
      </v-col>
    </v-row>

    <v-row v-if="mode !== ''">
      <v-col cols="12">
        <AdminResourceEdit :loading="loading" :table-data="this.mode === 'Questions' ? this.questions : this.questionnaires" :mode="mode"></AdminResourceEdit>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import AdminActionChooser from "../components/AdminActionChooser";
import AdminResourceEdit from "../components/AdminResourceEdit";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Admin',
  components: {AdminResourceEdit, AdminActionChooser},
  data() {
    return {
      loading: false,
      mode: ''
    }
  },
  methods: {
    ...mapActions('admin', ['getQuestions']),
    ...mapActions('app',['getQuestionnaires']),
    retrieveQuestions() {
      this.loading = true
      this.getQuestions()
      this.mode = 'Questions'
    },
    retrieveQuestionnaire() {
      this.loading = true
      this.getQuestionnaires()
      this.mode = 'Questionnaires'
    }
  },
  computed: {
    ...mapGetters('admin', ['questions']),
    ...mapGetters('app',['questionnaires'])
  },
  watch: {
    questions() {
      this.loading = false
    },
    questionnaires() {
      this.loading = false
    }
  }
}
</script>
