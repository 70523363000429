<template>
  <div>
    <v-card shaped elevation="6">
      <v-form v-model="valid">
        <!--    The actual Question  -->
        <v-card-title>
          <v-row class="ma-0 py-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea v-model="question.askedQuestion" :readonly="readOnly" outlined auto-grow label="Întrebarea ta"
                          :rows="1"></v-textarea>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider/>

        <!--    The answer of the question  -->
        <v-card-text>
          <v-textarea v-model="question.answer" :readonly="!isUserAdmin" shaped auto-grow outlined
                      label="Raspunsul Profesorului"
                      :rows="2" :counter="isUserAdmin" :rules="question.answer ? responseRules : [(el) => false]">
            <template slot="append-outer" v-if="isUserAdmin">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary"
                          @click="responseToQuestion"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!valid || loading">fa-paper-plane
                  </v-icon>
                </template>
                <span>Apasă aici pentru a raspunde la întrebare.</span>
              </v-tooltip>
            </template>
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-row justify="space-between" class="text-center">

            <v-col :cols="$vuetify.breakpoint.mdAndUp || infoMode ? 4 : 6" class="text-left">
              <v-chip :color="question.active ? 'success' : 'warning'">{{
                  question.active ? 'Răspuns' : 'În Așteptare'
                }}
              </v-chip>
            </v-col>

            <v-col cols="4" class="text-center" v-if="infoMode">
              <v-btn color="primary" rounded @click="$emit('more-info')">
                <v-icon :left="$vuetify.breakpoint.mdAndUp" size="25">mdi-information-variant</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Mai multe informatii</span>
              </v-btn>
            </v-col>

            <v-col cols="4" class="text-right">
              <v-btn color="error" rounded @click="deleteConfirmationDialog = true">
                <v-icon :left="$vuetify.breakpoint.mdAndUp" size="25">mdi-delete</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">Șterge Întrebarea</span>
              </v-btn>
            </v-col>


          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <!--        Delete Dialog Confirmation    -->
    <v-dialog v-if="deleteConfirmationDialog"
              v-model="deleteConfirmationDialog"
              max-width="320"
              :retain-focus="false">

      <ConfirmationCard
          title="Sterge"
          text="Doresti sa stergi aceasta intrebare?"
          button-one-text="Sterge"
          button-one-icon="mdi-delete"
          button-two-text="Anuleaza"
          button-two-icon="mdi-close-octagon"
          @button-one-click="deleteCommentById"
          @button-two-click="deleteConfirmationDialog = false"/>

    </v-dialog>
  </div>
</template>


<script>


import {mapActions, mapGetters} from "vuex";
import ConfirmationCard from "../ConfirmationCard";

export default {
  name: 'QuestionDisplayAndAnswer',
  props: {
    question: {
      type: Object,
      required: true
    },
    infoMode: {
      type: Boolean,
      default: false
    }
  },
  components: {ConfirmationCard},
  data() {
    return {
      readOnly: true,
      responseRules: [(response) => response.length > 15 || "Răspunsul tau trebuie sa aibă minim 15 caractere.",
        (response) => response.length < 1000 || "Răspunsul tau trebuie sa aibă maxim 1000 caractere."],
      valid: false,
      loading: false,
      deleteLoading: false,
      deleteConfirmationDialog: false
    }
  },
  methods: {
    ...mapActions('app', ['answerComment', 'deleteComment']),
    responseToQuestion() {
      this.loading = true
      this.answerComment({
        commentId: this.question.id,
        answer: this.question.answer
      })
    },
    deleteCommentById() {
      this.deleteLoading = true
      this.deleteComment(this.question.id)
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn', 'getUserData']),
    ...mapGetters('app', ['getAnswerCommentsStatus', 'getDeleteCommentStatus']),
    isUserAdmin() {
      return this.getUserData.maxAuthorizationType === 'ADMIN'
    }
  },
  watch: {
    getAnswerCommentsStatus(newValue) {
      //executes for every component
      if (newValue.status === 'success') {
        //this.question.active = true
      } //else if (newValue.status === 'fail')
      //   this.$toast.error(newValue.message, '', {position: "topCenter"});
      //moved to parent (show it just once)
      this.loading = false

    },
    getDeleteCommentStatus() {
      this.deleteLoading = false

    }
  },
  mounted() {
  }
}
</script>

<style lang="sass" scoped>


</style>
