import Vue from 'vue'
import 'font-awesome/css/font-awesome.min.css'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css';
import VueAnalytics from 'vue-analytics';




Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'G-X28ZMLB5HD',
  router
});

Vue.use(VueIziToast);
Vue.use(vuetify, {
  iconfont: 'fa4'
})

//Setup cookies
Vue.use(VueCookies)
Vue.$cookies.config('60d')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')




