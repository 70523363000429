import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default {
    async retrieveUserData({commit, getters}) {
        await axios.get('/user/me', {
            headers: {
                'Authorization': `Bearer ` + getters.getJWTToken
            }
        })
            .then(response => {
                commit('SET_USER_LOG_IN_STATUS', true)
                commit('SET_USER_DATA', response.data)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_USER_LOG_IN_STATUS', false)
            })


    },
    storeJWTToken({commit}) {
        if (window.$cookies.isKey('auth_token'))
            commit('SET_USER_JWT_TOKEN', window.$cookies.get('auth_token'));
    },
    async login({commit}, data) {
        const loginRequest = {
            email: data.email,
            password: data.password
        }

        let loginResponse = {status: 'success', method: 'Log in'}

        await axios.post('/auth/login', loginRequest, {withCredentials: true}).then(res => {
            loginResponse.token = res.data.accessToken
        })
            .catch(err => {
                let message = 'Serverul de Autentificare intampina probleme la acest moment. Incearca din nou mai tarziu!'
                if (err.response) {
                    if (err.response.status === 401)
                        message = 'Date de autentificare invalide!'
                    else if (err.response.status >= 500 && data.response.status < 600)
                        message = 'Serverul de Autentificare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                    else
                        message = 'Te rugam incearca din nou mai tarziu!'

                }

                loginResponse = {status: 'failed', method: 'Log in', message: message}
            })

        commit('SET_LOGIN_RESPONSE', loginResponse)

    },
    async signUp({commit}, data) {
        const loginRequest = {
            name: data.name,
            email: data.email,
            password: data.password
        }

        let signUpResponse = {status: 'success', method: 'Inregistrare'}

        await axios.post('/auth/signup', loginRequest, {withCredentials: true})
            .catch(err => {
                let message = 'Serverul de Autentificare intampina probleme la acest moment. Incearca din nou mai tarziu!'
                if (err.response) {
                    if (err.response.status === 400)
                        message = 'Email-ul pe care l-ai utilizat este deja inregistrat!'
                    else if (err.response.status === 401)
                        message = 'Date de autentificare invalide!'
                    else if (err.response.status >= 500 && data.response.status < 600)
                        message = 'Serverul de Autentificare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                    else
                        message = 'Te rugam incearca din nou mai tarziu!'

                }

                signUpResponse = {status: 'failed', method: 'Inregistrare', message: message}
            })

        commit('SET_SIGN_UP_RESPONSE', signUpResponse)
    },
    signOut({commit}) {
        commit('SIGN_OUT_USER')
    },
    async resetPassword({commit, getters}, {password, resetCode}) {
        await axios.post('/user/recovery/reset_password', {password: password, resetCode: resetCode}, {
            headers: {
                'Authorization': `Bearer ` + getters.getJWTToken
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_CHANGE_PASSWORD_STATUS', {status: 'success', message: 'Parola a fost schimbata cu succes!'})
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 400)
                        commit('SET_CHANGE_PASSWORD_STATUS', {
                            status: 'fail',
                            message: 'Cerearea de schimbare a parolei a expirat!'
                        })
                } else
                    commit('SET_CHANGE_PASSWORD_STATUS', {status: 'fail', message: 'Parola nu a putut fi schimbata!'})
            })
    },
    async changePassword({commit, getters}, password) {
        await axios.post('/user/change_password', {newPassword: password}, {
            headers: {
                'Authorization': `Bearer ` + getters.getJWTToken
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_CHANGE_PASSWORD_STATUS', {status: 'success', message: 'Parola a fost schimbata cu succes!'})
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_CHANGE_PASSWORD_STATUS', {status: 'fail', message: 'Parola nu a putut fi schimbata!'})
            })
    },
    async recoveryPassword({commit, getters}, email) {
        await axios.post('/user/recovery/recover_password', {email: email}, {
            headers: {
                'Authorization': `Bearer ` + getters.getJWTToken
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_PASSWORD_RECOVERY_STATUS', {
                    status: 'success',
                    message: 'Dacă adresa este corectă, va fi trimis un e-mail de confirmare. Dacă nu primiți e-mailul, verificați folderul spam.'
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 400)
                        commit('SET_PASSWORD_RECOVERY_STATUS', {
                            status: 'fail',
                            message: 'Ai deja o cerere de resetare a parolei. Verifica-ti inca o data email-ul (inclusiv SPAM)! Daca nu ai primit nici un mesaj, mai incearca inca o data in 10 minute!'
                        })
                } else
                    commit('SET_PASSWORD_RECOVERY_STATUS', {
                        status: 'fail',
                        message: 'Resetarea parolei nu a functionat! Incearca din nou mai tarziu.'
                    })
            })
    },
    async requestEmailActivation({commit, getters}) {
        await axios.get('/auth/validate', {
            headers: {
                'Authorization': `Bearer ` + getters.getJWTToken
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_ACTIVATE_EMAIL_REQUEST_STATUS', {
                    status: 'success',
                    message: 'Dacă adresa este corectă, va fi trimis un e-mail de confirmare pentru activare. Dacă nu primiți e-mailul, verificați folderul spam.'
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 400)
                        commit('SET_ACTIVATE_EMAIL_REQUEST_STATUS', {
                            status: 'fail',
                            message: 'Ai deja o cerere de verificare a e-mailului. Verifica-ti inca o data email-ul (inclusiv SPAM)! Daca nu ai primit nici un mesaj, mai incearca inca o data in 10 minute!'
                        })
                } else
                    commit('SET_ACTIVATE_EMAIL_REQUEST_STATUS', {
                        status: 'fail',
                        message: 'Cerearea de activare a contului nu a functionat! Incearca din nou mai tarziu.'
                    })
            })
    },
    async verifyEmail({commit}, code) {
        await axios.get('/auth/verify?code=' + code)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_EMAIL_VERIFY_STATUS', {
                    status: 'success',
                    message: 'E-mail verificat cu succes!'
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 400)
                        commit('SET_EMAIL_VERIFY_STATUS', {
                            status: 'fail',
                            message: 'Cererea de activare a expirat! Cere o noua cerere apasand butonul email din pagina de profil.'
                        })
                } else
                    commit('SET_EMAIL_VERIFY_STATUS', {
                        status: 'fail',
                        message: 'Cerearea de activare a contului nu a functionat! Incearca din nou mai tarziu.'
                    })
            })
    }
}
