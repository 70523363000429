<template>
  <v-container>
    <!--  Description  -->
    <v-row justify="space-around">
      <v-col cols="12" md="10">
        <v-card shaped>
          <h1 class="title">
            <v-card-title class="justify-center text-center text-break"><span v-if="beforeSummary">Înainte de a continua către rezultatele testului
            ne-am gândit ca vrei sa afli despre conținutul Aplicatiei "Teste Auto Explicate" in
            format fizic!</span> <span v-else>Conținutul Aplicatiei "Teste Auto Explicate" in
            format fizic! </span>
            </v-card-title>
          </h1>
          <v-card-text class="subtitle-1 text-center text-break">Sunteți adeptulul invațatului clasic?</v-card-text>
          <v-card-text class="font-weight-black text-center text-break">Teste Auto Explicate va oferă continutul
            aplicației si in format fizic prin intermediul cărtilor de mai jos.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--  Book Cards With Info -->
    <v-row justify="space-around">
      <v-col cols="12" md="5" v-for="book in books" :key="book.id" class="justify-center"
             style="max-height:700px; max-width:400px">
        <v-hover
            v-slot="{ hover }"
        >
          <v-img :src="book.imageSrc" contain class="elevation-12">
            <v-fade-transition>
              <v-overlay absolute v-if="hover" opacity="0.6">
                <v-row justify="center">
                  <v-col class="justify-center">
                    <v-card class="ma-3 justify-center" max-width="380">
                      <v-card-title class="justify-center">
                        {{ book.title }}
                      </v-card-title>
                      <v-card-text class="text-center text-wrap">
                        {{ book.description }}
                      </v-card-text>
                      <v-card-actions class="ma-5 justify-center">
                        <v-btn class="pointer text-center" rounded outlined x-large><span>Preț: </span><span
                            class="font-weight-bold">{{ book.price }}</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-overlay>
            </v-fade-transition>

          </v-img>
        </v-hover>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col cols="12" md="10">
        <v-card rounded>
          <v-card-title class="justify-center text-break text-center">Dorești sa comanzi?</v-card-title>
          <v-card-text class="text-center text-break">Dați click pe cele 2 coperți pentru a afla câteva amănunte despre
            cele două cărți.
          </v-card-text>
          <v-card-text class="text-center text-break">Puteți comanda cartile de mai sus la numarul de telefon: <span
              class="font-weight-bold">0728 954 121</span></v-card-text>
          <v-card-actions class="text-center justify-center my-2" v-if="beforeSummary">
            <v-btn color="primary" @click="$emit('close-dialog')">Continuă catre rezultate</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'AdCards',
  components: {},
  props: {
    beforeSummary: {
      default: false
    }
  },
  data() {
    return {
      books: [
        {
          id: "TESTE_AUTO",
          title: "Teste Auto Explicate",
          description: "Sunt sigur că după ce veți citi această carte, o să mă considerați\n" +
              "                      prietenul vostru.\n" +
              "                      Pentru că veți învăța foarte ușor răspunsurile corecte la întrebări.\n" +
              "                      Veți învăța de două ori mai repede decât de pe oricare altă carte.\n" +
              "                      Asta deoarece eu folosesc cam de două ori mai puține cuvinte la\n" +
              "                      întrebări și răspunsuri.\n" +
              "                      Pentru că eu exprim o idee cu un număr minim de cuvinte.\n" +
              "                      Dacă doriți sa învățati rapid într-un format clasic va recomandăm achizitionarea acestei cărți.",
          price: "30 Lei",
          imageSrc: require("@/assets/ads/coperta_teste.png")
        },
        {
          id: "COD_RUTIER",
          title: "Codul Rutier Explicat",
          description: "Aproape toţi elevii mă rugau să le subliniez ce să citească din acele \n" +
              "cărţi groase de legislaţie. \n" +
              "Acum pot citi tot ce scrie în carte. Am scris în această carte doar ce \n" +
              "le trebuie elevilor pentru examen.\n" +
              "Am dat şi multe explicaţii, astfel că elevilor le va fi foarte uşor să înţeleagă tot, citind cartea o singură dată.\n " +
              "În plus, cartea conține si chestionarele pentru redobândire.",
          price: "20 Lei",
          imageSrc: require("@/assets/ads/coperta_cod_rutier.png")
        }
      ]
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="sass">

.pointer
  cursor: default !important

</style>
