export default {
    //disable the ask question feature
    disableAskQuestionFeature: true,
    questionnaires: {
        payload: []
    },
    loadedQuestionnaire: null,
    legislation: {
        chapters: null,
        chaptersDataMap: new Map()
    },
    statisticsUploadStatus: null,
    questionnairesStatisticsScores: null,
    commentAskedStatus: null,
    comments: [],
    fullRetrievedComments: false,
    answerCommentsStatus: null,
    deleteCommentStatus: null,
    submitIssueStatus: null
}
