<template>
  <nav>
    <v-app-bar flat app color="transparent" hide-on-scroll>
      <v-app-bar-nav-icon class="black--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase black--text">
        <span class="font-weight-light">Teste Auto </span>
        <span>Explicate</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>


      <!-- Dropdown Menu -->

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color="black" v-on="on">
            <v-icon>mdi-chevron-down</v-icon>
            <span>Meniu</span>
          </v-btn>
        </template>
        <v-list>
          <template v-for="link in links">
            <v-list-item :to="link.route" :key="link.text" :exact="true" rel="canonical"
                         v-if="link.id !== 'REPORT'"
                         v-show="isUserLoggedIn ? !hasAuthority(link.access, getUserData.maxAuthorizationType) : !hasAuthority(link.access,'FREE_USER')">
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

      </v-menu>


      <v-btn text color="black" @click="topLeftButtonClick(userLoginButton.clickAction)">
        {{ userLoginButton.status }}
        <v-icon right>{{ userLoginButton.icon }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" expand-on-hover mini-variant
                         class="primary">
      <v-row justify="center">
        <v-col class="mt-5 text-center">
          <router-link to="/profil">
            <v-avatar size="45">
              <v-img :src="getUserImageSource"/>
            </v-avatar>
            <!--            <p class="white&#45;&#45;text subtitle-1 mt-1">Ionescu Andrei</p>-->
          </router-link>
          <!--          <span class="font-weight-bold white&#45;&#45;text"> Andrei Ionescu </span>-->
        </v-col>
      </v-row>


      <!--    Left Routes   -->
      <v-list>
        <template v-for="link in links">
          <v-list-item :to="link.route" :exact="true" :key="link.id" rel="canonical"
                       v-show="isUserLoggedIn ? !hasAuthority(link.access, getUserData.maxAuthorizationType) : !hasAuthority(link.access,'FREE_USER')"
                       @click="link.id === 'REPORT' ? reportDialog = true : ''">
            <v-list-item-action>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>


      <!--      <v-row class="mt-4 mb-3" justify="center">-->
      <!--        <Popup/>-->
      <!--      </v-row>-->

      <v-dialog v-model="reportDialog" max-width="800px">
        <ReportIssue @close-dialog="reportDialog = false"/>
      </v-dialog>

    </v-navigation-drawer>
  </nav>
</template>


<script>
//import Popup from "@/components/Popup";

import {mapActions, mapGetters} from "vuex";
import router from "../router";
import {hasAuthority} from "../utilities/AuthUtilities";
import ReportIssue from "../components/ReportIssue";

export default {
  name: "NavBar",
  components: {
    ReportIssue
  },
  data() {
    return {
      reportDialog: false,
      drawer: false,
      avatarSize: 100,
      links: [
        {
          id: 'HOME',
          icon: 'mdi-home',
          text: 'Pagină Principală',
          route: '/',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'LEGISLATION',
          icon: 'mdi-book-open',
          text: 'Codul Rutier Explicat',
          route: '/legislatie',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'LEARNING',
          icon: 'mdi-school',
          text: 'Mod Învățare',
          route: '/chestionare/invatare',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'QUESTIONNAIRES',
          icon: 'mdi-book-edit',
          text: 'Teste Auto',
          route: '/chestionare',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'REDOBANDIRE',
          icon: 'mdi-rename-box',
          text: 'Redobândire',
          route: '/chestionare/redobandire',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'QUESTIONS',
          icon: 'mdi-chat-question',
          text: 'Întrebările Mele',
          route: '/intreaba',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'BOOKS',
          icon: 'fa-book',
          text: 'Cărți',
          route: '/carti',
          class: '',
          access: "FREE_USER"
        },
        // {
        //   id: 'PREMIUM',
        //   icon: 'mdi-gold',
        //   text: 'Abonamente',
        //   route: '/premium',
        //   class: '',
        //   access: "FREE_USER"
        // },
        {
          id: 'PROFILE',
          icon: 'mdi-account',
          text: 'Profilul Meu',
          route: '/profil',
          class: '',
          access: "FREE_USER"
        },
        {
          id: 'ADMIN',
          icon: 'mdi-badge-account',
          text: 'Admin Panel',
          route: '/admin/panel',
          class: '',
          access: "ADMIN"
        },
        // {
        //   icon: 'mdi-book-open-blank-variant',
        //   text: 'Carti Legislatie',
        //   route: '/carti',
        //   class: '',
        //   access: "FREE_USER"
        // },
        {
          id: 'REPORT',
          icon: 'mdi-bug',
          text: 'Raportează o problemă',
          route: '',
          class: '',
          access: "FREE_USER",
          align: "bottom"
        }

      ]
    }
  },
  beforeMount() {
    if (this.$vuetify.breakpoint.mdAndUp)
      this.drawer = true
  },
  mounted() {
    //if the questions are disabled do not display the questions
    if (this.isQuestionFeatureDisabled) {
      this.links.splice(this.links.map((el) => el.id).indexOf('QUESTIONS'), 1)
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData']),
    ...mapGetters('app', ['isQuestionFeatureDisabled']),
    userLoginButton() {
      if (!this.isUserLoggedIn)
        return {status: 'Login/Register', icon: 'fa-sign-in', clickAction: 'routeToSignUp'}
      else
        return {status: 'Sign Out', icon: 'mdi-exit-to-app', clickAction: 'signOut'}
    },
    getUserImageSource() {
      if (this.getUserData.imageUrl)
        return this.getUserData.imageUrl
      else
        return require('@/avatars/avatar1.svg')
    },
    ...mapGetters('auth', ['isUserLoggedIn', 'getUserData'])
  },
  methods: {
    hasAuthority,
    ...mapActions('auth', ['signOut']),
    routeToSignUp() {
      router.push('sign-up').catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
        ) {

          //console.log(err);
        }
      });
    },
    topLeftButtonClick(action) {
      if (action === 'routeToSignUp')
        this.routeToSignUp();
      else if (action === 'signOut') {
        this.$toast.success('Sign out reușit!', '', {position: "topCenter"});
        this.signOut()
      }
    }
  }
}
</script>
