<template>
  <div>
    <v-card v-if="!showWrongQuestions">
      <div>
        <!-- Title And Image For Passing/Failing -->
        <v-card-title>
          <v-row justify="center"></v-row>
          <v-col cols="12">

            <div class="title text-uppercase font-weight-bold text-center">
              <div class="success--text text-center" v-if="passed">
                <v-img class="mx-auto" :src="require('@/assets/passing/ADMIS.png')"
                       :lazy-src="'@/assets/passing/ADMIS.png'"
                       max-height="420px" max-width="400px"/>
                <div v-if="breakpoint">Felicitări! Ai Trecut Examenul Teoretic!</div>
              </div>
              <div v-else>
                <v-img class="mx-auto" :src="require('@/assets/passing/RESPINS.png')"
                       :lazy-src="'@/assets/passing/RESPINS.png'"
                       max-height="420px" max-width="400px"/>
                <span class="red--text lighten-2 text-center">
                <div v-if="breakpoint">Din păcate ai picat examenul teoretic!</div>
              </span></div>
            </div>
          </v-col>
        </v-card-title>

        <v-card-text class="justify-space-around">
          <!--        <p class="title text-uppercase text-center"> Rezultat chestionar: </p>-->
          <v-row justify="space-between">
            <v-col cols="2" v-for="stat in stats" :key="stat.text" class="text-h6 justify-center">

              <!--   Display the stats -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-row :class="breakpoint ? 'subtitle-2 text-center fa-border' : ''" v-on="on"
                         v-bind="attrs" justify="center" align-content="center" align="center">
                    <v-col cols="8" sm="6" class="justify-center">
                      <v-icon class="center" left :x-large="breakpoint" :color="stat.color">{{ stat.icon }}</v-icon>
                      <!--              {{stat.text}}:-->
                    </v-col>
                    <v-col cols="8" sm="6" class="justify-center">
                      <v-chip class="text-center" outlined :color="stat.color" style="min-width: 35px">{{
                          stat.value
                        }}
                      </v-chip>
                    </v-col>

                  </v-row>
                </template>
                <span>
                {{ stat.text }}
              </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="my-3"/>
        <!--      Action Buttons (Like back or redo)  -->
        <v-card-text class="text-center">
          <v-row justify="space-around">
            <v-col cols="4" v-for="navButton in navButtons" :key="navButton.text">
              <v-btn :x-large="breakpoint" elevation="2" :color="navButton.color"
                     light
                     rounded
                     @click="navButtonClicked(navButton.action)"
                     v-if="navButton.id !== 'WRONG' || wrongQuestions.length > 0">
                <v-icon :left="breakpoint"
                        size="30"
                        color="yellow darken-4">{{ navButton.icon }}
                </v-icon>
                <div v-if="breakpoint">&nbsp;{{ navButton.text }}</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <!-- Display Wrong Questions -->
    <div v-else>
      <WrongQuestionsDisplay :wrong-questions="wrongQuestions"/>
    </div>


  </div>
</template>

<script>


import WrongQuestionsDisplay from "./WrongQuestionsDisplay";

export default {
  name: 'QuestionnaireSummary',
  components: {WrongQuestionsDisplay},
  props: {
    minPassing: {
      type: Number,
      required: true
    },
    correctAnswers: {
      type: Number,
      required: true
    },
    wrongAnswers: {
      type: Number,
      required: true
    },
    initialQuestionNo: {
      type: Number,
      required: true
    },
    questionnaireId: {
      type: Number,
      required: true
    },
    wrongQuestions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      showWrongQuestions: false,
      stats: [
        {
          text: 'Numar total de intrebari',
          icon: 'mdi-clipboard-list-outline',
          value: this.initialQuestionNo,
          color: "black"
        },
        {
          text: 'Raspunsuri corecte',
          icon: 'mdi-check-outline',
          value: this.correctAnswers,
          color: "success"
        },
        {
          text: 'Raspunsuri gresite',
          icon: 'mdi-close-circle-outline',
          value: this.wrongAnswers,
          color: "error"
        },
        {
          text: 'Numar de intrebari ce trebuiau rezolvate pentru a trece examenul',
          icon: 'fa-graduation-cap',
          value: this.minPassing,
          color: "blue lighten-1"
        }
      ],
      navButtons: [
        {
          id: "TRY AGAIN",
          text: 'Incearcă din nou',
          icon: 'fa-repeat',
          action: 'REDO',
          color: "transparent"
        },
        {
          id: "WRONG",
          text: 'Artă-mi ce am greșit!',
          icon: 'fa-eye',
          action: 'SHOW_WRONG',
          color: "red lighten-5"
        },
        {
          id: "GO BACK",
          text: 'Intoarce-te la chestionare',
          icon: 'mdi-book-edit',
          action: 'BACK',
          color: "transparent"
        }

      ]
    }
  },
  methods: {
    navButtonClicked(action) {
      if (action === 'REDO') {
        this.$router.go()
        //this.$router.push('/chestionare')
        this.$router.push('/chestionar/' + this.questionnaireId)
      } else if (action === 'BACK') {

        this.$router.push(this.$route.query.root ? this.$route.query.root : '/chestionare')
      } else if (action === 'SHOW_WRONG') {
        this.showWrongQuestions = true
        let navActions = this.navButtons.map((el) => el.action)
        //remove show wrong button
        this.navButtons.splice(navActions.indexOf("SHOW_WRONG"), 1)
      }
    }
  },
  watch: {},
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    passed() {
      return this.correctAnswers >= this.minPassing
    }
  }
}
</script>

<style scoped lang="sass">


</style>
