<template>
  <div>
    <v-row align="center" justify="center" align-content="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="elevation-12">
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-row>
                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1
                        class="text-center display-2 black--text text--accent-3 py-5"
                    >Login Teste Auto Explicate</h1>
                    <!--                    <div class="text-center mt-4">-->
                    <!--                      <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                        <v-icon class="fa" color="">fa-facebook</v-icon>-->
                    <!--                      </v-btn>-->

                    <!--                      <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                        <v-icon class="fa">fa-google</v-icon>-->
                    <!--                      </v-btn>-->
                    <!--                    </div>-->
                    <!--                    <h4 class="text-center mt-4">Introdu-ti credentialele mai jos sau logheaza-te cu Facebook sau-->
                    <!--                      Google apasand butoanele de mai sus!</h4>-->
                    <v-form v-model="isLoginFormValid" :disabled="loading">
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="mdi-email"
                          type="text"
                          color="primary accent-3"
                          :rules="emailRules"
                          v-model="email"
                      />

                      <v-text-field
                          id="password"
                          label="Parola"
                          name="Parola"
                          prepend-icon="mdi-lock"
                          :type="!appendClicked ? 'password': 'text'"
                          color="primary accent-3"
                          :rules="passwordRules"
                          v-model="password"
                          :append-icon="!appendClicked ? 'mdi-eye-off' :'mdi-eye'"
                          @click:append="appendClicked = !appendClicked"
                      />
                    </v-form>
                    <h3 class="text-center mt-4">
                      <v-btn
                          rounded
                          outlined
                          depressed
                          plain
                          style="border-style: none"
                          color="black--text"
                          :to="'/cont/parola/recuperare'">Ti-ai uitat parola?
                      </v-btn>
                    </h3>
                  </v-card-text>
                  <div class="text-center mt-3 my-3">
                    <v-btn rounded color="primary accent-3 py-2" :disabled="!isLoginFormValid || loading"
                           @click="loginUser"
                           :loading="loading">Log In
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="primary accent-3">
                  <v-card-text class="white--text mt-12">
                    <h1 class="text-center display-1">Salut!</h1>
                    <h5
                        class="text-center"
                    >Daca nu ai cont inregistreaza-te apasand butonul de mai jos:</h5>
                  </v-card-text>
                  <div class="text-center py-4">
                    <v-btn rounded outlined dark @click="step++">Inregistreaza-te</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col cols="12" md="4" class="primary accent-3">
                  <v-card-text class="white--text mt-12">
                    <h1 class="text-center display-1">Bine ai revenit!</h1>
                    <h5
                        class="text-center"
                    >Daca ai deja un cont te rugam sa te loghezi apasand butonul de mai jos!</h5>
                  </v-card-text>
                  <div class="text-center">
                    <v-btn rounded outlined dark @click="step--">Log In</v-btn>
                  </div>

                </v-col>

                <v-col cols="12" md="8">
                  <v-card-text class="mt-12">
                    <h1 class="text-center display-2 black--text text--accent-3 py-5">Creaza-ti cont</h1>
                    <!--                    <div class="text-center mt-4">-->
                    <!--                      <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                        <v-icon class="fa" color="">fa-facebook</v-icon>-->
                    <!--                      </v-btn>-->

                    <!--                      <v-btn class="mx-2" fab color="black" outlined>-->
                    <!--                        <v-icon class="fa">fa-google</v-icon>-->
                    <!--                      </v-btn>-->
                    <!--                    </div>-->
                    <!--                    <h4 class="text-center mt-4">Introdu-ti credentialele mai jos sau inregistreaza-te cu Facebook sau-->
                    <!--                      Google apasand butoanele de mai sus!</h4>-->
                    <v-form v-model="isSignUpFormValid" :disabled="loading">
                      <v-text-field
                          label="Nume Utilizator"
                          name="Nume Utilizator"
                          prepend-icon="mdi-account"
                          type="text"
                          color="primary accent-3"
                          :rules="nameRules"
                          v-model="userName"
                      />
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="mdi-email"
                          type="text"
                          color="primary accent-3"
                          :rules="emailRules"
                          v-model="email"
                      />

                      <v-text-field
                          id="password"
                          label="Parola"
                          name="Parola"
                          prepend-icon="mdi-lock"
                          :type="!appendClicked ? 'password': 'text'"
                          color="primary accent-3"
                          :rules="passwordRules"
                          v-model="password"
                          :append-icon="!appendClicked ? 'mdi-eye-off' :'mdi-eye'"
                          @click:append="appendClicked = !appendClicked"
                      />

                      <v-checkbox v-model="checkbox" :error="!checkbox"
                                  :rules="[(value) => value === true || 'Trebuie sa accepți termenii si condițiile']">
                        <template v-slot:label>
                          <div>
                            Accept
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                    v-on="on"
                                    @click="$router.push('/termene')"
                                >
                                  Termenii Si Condițiile
                                </a>
                              </template>
                              Vizualizează Termenii Si Condițiile
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>

                    </v-form>
                  </v-card-text>
                  <div class="text-center mt-n5 py-5">
                    <v-btn rounded color="primary accent-3" :disabled="!isSignUpFormValid || loading"
                           @click="signUpUser"
                           :loading="loading">
                      Inregistrare
                    </v-btn>
                  </div>
                  <!--                  <div class="text-center">-->
                  <!--                    <span class="font-weight-light text-sm-body-2">Înregistrarea pe site reprezintă acordul dumneavoastră cu privire la prelucrarea datelor cu caracter personal.</span>-->
                  <!--                  </div>-->
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SignUp',
  components: {},
  props: {
    source: String
  },
  data: () => ({
    step: 1,
    appendClicked: false,
    loading: false,
    checkbox: false,
    emailRules: [
      (email) => email.length < 320 || 'Email-ul poate avea maxim 320 de caractere',
      (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase()) === true || 'Email-ul introdus nu este valid.',
    ],
    passwordRules: [
      (password) => !(password.length > 30 || password.length < 8) === true || 'Parola trebuie sa aiba intre 8 si 30 de caractere.',
      (password) => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})").test(password) === true || 'Parola trebuie sa includa atat caractere mici cat si mari, numere, dar si simboluri precum !@#$%^&*'
    ],
    nameRules: [
      (name) => !(name.length > 50 || name.length < 1) === true || 'Numele trebuie sa aiba intre 1 si 50 de caractere.',
      (name) => /^[a-zA-Z0-9]+$/.test(name) === true || 'Numele nu contine doar litere si cifre.'
    ],
    isLoginFormValid: false,
    isSignUpFormValid: false,
    userName: '',
    email: '',
    password: '',
  }),
  methods: {
    //LOGIN THE USER
    async loginUser() {
      this.loading = true
      //LOGIN
      await this.login({email: this.email, password: this.password})

      //STORE THE JWT TOKEN
      await this.storeJWTToken()

      //RETRIEVE DATA ABOUT THE USER (/me)
      await this.retrieveUserData()

      // //wait some time and redirect the user to the main page
      // if (this.isUserLoggedIn && this.$route.query.error) {
      //   console.log("redirect")
      //   this.$router.push({path: '/'})
      //   //setTimeout(() => this.$router.push({path: '/'}), 300);
      // }

    },
    //SIGN-UP USER BASED ON INPUT DATA
    async signUpUser() {
      this.loading = true
      await this.signUp({email: this.email, password: this.password, name: this.userName})
    },
    ...mapActions('auth', ['login', 'signUp', 'retrieveUserData', 'storeJWTToken'])
  },
  computed: {
    ...mapGetters('auth', ['getLoginResponse', 'getSignUpResponse', 'getUserData', 'isUserLoggedIn']),
    getError() {
      if (this.$route.query.error)
        return this.$route.query.error
      else return null
    }
  },

  watch: {
    //SET UP LOGIN NOTIFICATION
    getLoginResponse(newValue) {
      if (newValue.status === 'success')
        this.$toast.success('Bine ai venit!', newValue.method + ' Reușit', {position: "topCenter"});

      if (newValue.status === 'failed')
        this.$toast.error(newValue.message, newValue.method, {position: "topCenter"});

      this.loading = false
    },
    isUserLoggedIn(newValue) {
      //if user is logged in in the meantime, redirect him to the correct route
      if (newValue === true){
        if(this.$route.query.error)
          this.$router.push(this.$route.query.error)
        else
          this.$router.push('/')
      }
    },
    //SET UP SIGN-UP NOTIFICATION
    getSignUpResponse(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success('Inregistrare Reusita. Verifica-ti adresa de email pentru a-ti confirma contul! (Verifica si casuta SPAM)', newValue.method, {position: "topCenter"});
        //if success login the user automatically
        this.loginUser()
      }

      if (newValue.status === 'failed')
        this.$toast.error(newValue.message, newValue.method, {'position': "topCenter"});

      this.loading = false
    },
    //display error message when it changes (same component, different errors)
    getError(newValue) {
      if (newValue)
        this.$toast.warning("Trebuie să fii logat pentru a accesa pagina de " + this.$route.query.error.replaceAll("/", " "), 'Autentificare', {position: "topCenter"})
    }
  },
  mounted() {
    //display error message if the component starts with an error
    if (this.$route.query.error && !window.$cookies.isKey('auth_token'))
      this.$toast.warning("Trebuie să fii logat pentru a accesa pagina de " + this.$route.query.error.replaceAll("/", " "), 'Autentificare', {position: "topCenter"})
  }

};
</script>
