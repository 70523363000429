<template>
  <v-card class="text-center">
    <v-card-title class="justify-center">
      {{ title }}
    </v-card-title>
    <v-card-text style="white-space: pre-line;">{{ text }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="red lighten-1"
          text
          @click="buttonOneClick"
          class="ma-2"
      >
        <v-icon large left>{{ buttonOneIcon }}</v-icon>
        {{ buttonOneText }}
      </v-btn>
      <v-btn
          color="primary lighten-1"
          text
          class="ma-2"
          @click="buttonTwoClick"
      >
        <v-icon large left>{{ buttonTwoIcon }}</v-icon>
        {{ buttonTwoText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    buttonOneText: String,
    buttonTwoText: String,
    buttonOneIcon: String,
    buttonTwoIcon: String,
    title: String,
    text: String,
    dialog: Boolean
  },
  data() {
    return {}
  },
  methods: {
    buttonOneClick() {
      this.$emit('button-one-click')
    },
    buttonTwoClick() {
      this.$emit('button-two-click')
    }

  }
}
</script>
