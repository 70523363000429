<template>
  <div>
    <v-select
        v-model="modelValue"
        :items="categories"
        menu-props="auto"
        label="Categorie Auto"
        hide-details
        prepend-inner-icon="fa-car"
        single-line
        :readonly="readOnly"
        :disabled="readOnly"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'CategoryChooser',
  props: {
    value: {
      type: String,
      default: 'B',
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: ['A', 'B', 'C', 'D', 'E', 'TR', 'REDOBANDIRE']
    }
  },
  methods: {},
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
