<template>
  <div>
    <!--    ROW 1 WITH LOGO  -->
    <v-row justify="space-around">

      <!--      ICON WITH LOGO IN THE MIDDLE    -->
      <v-col cols="12" md="3" v-if="$vuetify.breakpoint.smAndDown">
        <v-card-title>
          <v-img contain src=".././assets/logos/teste-auto-explicate.webp"
                 max-height="370px"></v-img>
        </v-card-title>
        <v-card-text>
          <h1 hidden>Teste Auto Explicate</h1>
          <h2 class="text-center font-weight-bold text-break">INFORMAȚIILE DIN ACEASTĂ APLICAȚIE LE GĂSIȚI ȘI TIPĂRITE,
            ÎN CĂRȚILE CODUL RUTIER EXPLICAT ȘI TESTE AUTO EXPLICATE. COMENZI LA AUTORUL LOR, ING. ION HEREA, PROFESOR
            DE LEGISLAȚIE RUTIERĂ, LA TEL. 0728 954 121.</h2>
        </v-card-text>
      </v-col>

      <v-col cols=12 md=3 class="d-flex">
        <template>
          <v-card
              class="mx-auto"
              color="grey lighten-4"
              elevation="5"
          >
            <v-card-text
                class="pt-6"
                style="position: relative;"
            >
              <v-btn
                  absolute
                  color="primary"
                  class="white--text"
                  fab
                  large
                  right
                  top
                  to="/legislatie"
              >
                <v-icon>mdi-book-open</v-icon>
              </v-btn>

              <h3 class="text-h4 font-weight-light primary--text mb-2">
                Codul Rutier Explicat
              </h3>
              <div class="font-weight-light grey--text text-h6 mb-2">
                Categoriile A, B, C, D, E
              </div>
              <div class="font-weight-light text-h6 mb-2 text-break">
<!--                După logare, afli că ai Gratuit Capitolele Depășirea și Oprirea. <br><br>-->
                Este scurt, ușor de citit și de înțeles. Trebuie să-l citești o singură dată!<br>
                <br> Începe pregătirea cu acest capitol! Vei învăța mult mai ușor întrebările.
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-col>

      <!--      ICON WITH LOGO IN THE MIDDLE    -->
      <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title>
          <v-img contain src=".././assets/logos/teste-auto-explicate.webp"
                 max-height="370px"></v-img>
        </v-card-title>
        <v-card-text>
          <h2 class="text-center font-weight-bold text-break">INFORMAȚIILE DIN ACEASTĂ APLICAȚIE LE GĂSIȚI ȘI TIPĂRITE,
            ÎN CĂRȚILE CODUL RUTIER EXPLICAT ȘI TESTE AUTO EXPLICATE. COMENZI LA AUTORUL LOR, ING. ION HEREA, PROFESOR
            DE LEGISLAȚIE RUTIERĂ, LA TEL. 0728 954 121.</h2>
        </v-card-text>
      </v-col>

      <v-col cols=12 md=3 class="d-flex">
        <template>
          <v-card
              class="mx-auto"
              color="grey lighten-4"
              elevation="5"
          >
            <v-card-text
                class="pt-6"
                style="position: relative;"
            >
              <v-btn
                  absolute
                  color="primary"
                  class="white--text"
                  fab
                  large
                  right
                  top
                  to="/chestionare/invatare"
              >
                <v-icon>mdi-school</v-icon>
              </v-btn>

              <h3 class="text-h4 font-weight-light primary--text mb-2">
                Mod Învățare
              </h3>
              <div class="font-weight-light grey--text text-h6 mb-2">
                Categoria B, B1
              </div>
              <div class="font-weight-light text-h6 text-break">
<!--                După logare, afli că ai Gratuit Testele Rapide 1, 4, 15. <br><br>-->
                <!--                Parcurge mediul de învatare pentru a-ți perfectiona cunostințele pentru examenul auto teoretic-->
                <!--                categoria (B, B1).<br><br>-->
                Înainte de Teste (Simulare Examen), citește această Secțiune de două sau de trei ori! <br><br>
                Sunt aceleași întrebări! Ai în plus explicații pentru toate răspunsurile, astfel înveți rapid.

                <br><span v-if="!isQuestionFeatureDisabled">Pentru fiecare întrebare din "modul de invațare, poți întreba un profesor de legislatie rutiera
                nelămuririle tale.</span>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <!--   ROW 2  -->
    <v-row justify="space-around" :class="'mb-5' + $vuetify.breakpoint.mdAndUp ? 'mt-6' : ''">

      <v-col cols=12 md=3 class="d-flex">
        <v-card
            class="mx-auto"
            color="grey lighten-4"
            elevation="5"
        >
          <v-card-text
              class="pt-6"
              style="position: relative;"
          >
            <v-btn
                absolute
                color="primary"
                class="white--text"
                fab
                large
                right
                top
                to="/chestionare"
            >
              <v-icon>mdi-book-edit</v-icon>
            </v-btn>

            <h3 class="text-h4 font-weight-light primary--text mb-2">
              Teste Auto <br>
              (Simulare Examen)
            </h3>
            <div class="font-weight-light grey--text text-h6 mb-2">
              Categoria B, B1
            </div>
            <div class="font-weight-light text-h6 mb-2 text-break">
<!--              După logare, afli că ai Gratuit Chestionarele 1, 4, 15. <br><br>-->
              Aici lucrezi exact ca la examen. Cu cronometru și cu toate butoanele.<br>
              <br v-if="!isQuestionFeatureDisabled"> <span v-if="!isQuestionFeatureDisabled">Pentru fiecare întrebare din teste, poți întreba un profesor de legislatie rutiera nelămuririle
              tale.</span><br>
              <br v-if="!isQuestionFeatureDisabled">După fiecare test vezi greșelile și explicațiile pentru fiecare
              răspuns.
            </div>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols=12 md=3 class="d-flex">
        <v-card
            class="mx-auto"
            color="grey lighten-4"
            elevation="5"
        >
          <v-card-text
              class="pt-6"
              style="position: relative;"
          >
            <v-btn
                absolute
                color="primary"
                class="white--text"
                fab
                large
                right
                top
                to="/chestionare/redobandire"
            >
              <v-icon>mdi-rename-box</v-icon>
            </v-btn>

            <h3 class="text-h4 font-weight-light primary--text mb-2">
              Teste Redobândire
            </h3>
            <div class="font-weight-light grey--text text-h6 mb-2">
              <!--Teste Redobândire-->
            </div>
            <div class="font-weight-light text-h6 mb-2 text-break">
<!--              După logare, afli că ai Gratuit Chestionarele 1, 3. <br><br>-->
              Aici găsești testele 13 din 15 și răspunsurile corecte. <br><br>
              Cine trece testul, recuperează permisul mai repede: în 30 de zile, în loc de 60 ori 90 de zile.<br>
              <br v-if="!isQuestionFeatureDisabled"> <span v-if="!isQuestionFeatureDisabled">Pentru fiecare întrebare din teste, poți întreba un profesor de legislatie rutiera nelămuririle
              tale.</span><br>
              <br v-if="!isQuestionFeatureDisabled">
              <!--              La finalul fiecărui test poți vizualiza întrebarile greșite si-->
              <!--              explicațiile pentru fiecare răspuns.-->
            </div>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols=12 md=3 class="d-flex">
        <v-card
            class="mx-auto"
            color="grey lighten-4"
            elevation="5"
        >
          <v-card-text
              class="pt-6"
              style="position: relative;"
          >
            <v-btn
                absolute
                color="primary"
                class="white--text"
                fab
                large
                right
                top
                to="/carti"
            >
              <v-icon>fa-book</v-icon>
            </v-btn>

            <h3 class="text-h4 font-weight-light primary--text mb-2">
              Cărți
            </h3>
            <div class="font-weight-light grey--text text-h6 mb-2">
              Ești adeptul învățatului clasic?
            </div>
            <div class="font-weight-light text-h6 mb-2 text-break">
              <!--              "Teste Auto Explicate" îți oferă continutul aplicatiei si în in format fizic.-->
              <!--              <br><br>-->
              Poți cumpăra 2 cărți, Codul Rutier Explicat și Teste Auto Explicate.
              În ele găsești conținutul aplicației în format fizic.
              <br><br>
              COMENZI LA TEL. 0728 954 121.
            </div>
          </v-card-text>
        </v-card>

      </v-col>


      <v-col cols=12 md=3 class="d-flex" v-if="!isQuestionFeatureDisabled">
        <template>
          <v-card
              class="mx-auto"
              color="grey lighten-4"
              elevation="5"
          >
            <v-card-text
                class="pt-6"
                style="position: relative;"
            >
              <v-btn
                  absolute
                  color="primary"
                  class="white--text"
                  fab
                  large
                  right
                  top
                  to="/intreaba"
              >
                <v-icon>mdi-chat-question</v-icon>
              </v-btn>

              <h3 class="text-h4 font-weight-light primary--text mb-2">
                Întrebarile Mele
              </h3>
              <div class="font-weight-light text-h6 mb-2 text-break">
                Vizualizează intrebarile puse in timpul sesiunilor de învatare.
                <br> Profesorii noștri îți vor clarifica nelămuririle în cel mai scurt timp. <br>
                <br> Toate întrebarile tale vor primi un raspuns personalizat bazat pe cerințele tale.
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <div class="py-10"></div>

    <!--    Footer  with data -->
    <v-footer fixed inset light class="mb-0 pa-0 pt-5" justify="space-around"
              style="bottom: 0; align-self: end; position: absolute" height="80" color="transparent">
      <v-col cols="12" class="ma-0 pa-0 mb-0">
        <v-card
            flat
            tile
            class="text--black text-center"
            width="100vw"
            color="sea_light_blue"
        >

          <!-- First Footer Category -->
          <v-divider class="my-2"></v-divider>
          <v-card-text class="text--black pt-0 text-center">
            <v-row justify="space-around" class="text-center">
              <v-col cols="6" md="2">
                <v-list dense color="sea_light_blue" class="text-center">
                  <v-list-item-title class="font-weight-bold">Suport Clienți</v-list-item-title>
                  <v-list-item href="https://anpc.ro/" ripple class="justify-center">
                    ANPC
                  </v-list-item>
                  <v-list-item href="https://anpc.ro/categorie/1271/sal?ref=footer_3_6" ripple class="justify-center">
                    ANPC SAL
                  </v-list-item>
                  <v-list-item href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                               ripple class="justify-center">
                    SOL
                  </v-list-item>
                </v-list>

              </v-col>

              <!-- Second Footer Category -->
              <v-col cols="6" md="2">
                <v-list dense color="sea_light_blue" class="text-center">
                  <v-list-item-title class="font-weight-bold">Informații Utile</v-list-item-title>
                  <v-list-item to="/contact" ripple class="justify-center">
                    Contact
                  </v-list-item>
                  <v-list-item to="/termene" ripple class="justify-center">
                    Termene și Condiții
                  </v-list-item>
                </v-list>

              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>


          <v-card-text class="text--black">
            <v-row justify="center">

              <v-col cols="12" class="ma-0 pa-0">

                <v-list-item dense href="https://www.linkedin.com/in/andrei-cristian-ionescu/" ripple
                             class="justify-center">
                 <span style="vertical-align:middle;"> <v-icon class="mb-1" size="15" style="vertical-align: middle">fa-pencil-square</v-icon> &nbsp;
                  Aplicație web realizata de:
                  <strong style="vertical-align: middle">Andrei Ionescu</strong></span>
                </v-list-item>
              </v-col>

              <v-col cols="12">
                <v-icon x-small>fa-copyright</v-icon>
                {{ new Date().getFullYear() }} <strong>Teste Auto Explicate</strong>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Dashboard',
  data() {
    return {}
  },
  methods: {
    sortBy(string) {
      this.projects.sort((a, b) => a[string] < b[string] ? -1 : 1)
    }
  },
  computed: {
    ...mapGetters('app', ['isQuestionFeatureDisabled'])
  },
}
</script>

<style>
</style>
