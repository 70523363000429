<template>
  <div>
    <v-row justify="center">
      <h2>Termene si Condiții</h2>
    </v-row>

    <v-row justify="center" class="my-2">
      <h3>Teste Auto Explicate</h3>
    </v-row>
    <v-row v-for="(chapter,index) in terms" :key="index">
      <v-col cols="12">
        <v-card elevation="5">
          <v-card-title>
            {{ chapter.title }}
          </v-card-title>

          <v-card-text class="subtitle-1" style="white-space: pre-line">
            {{ chapter.contents }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>


export default {
  name: 'TermsAndConditions',
  components: {},
  data() {
    return {
      terms: [
        {
          title: "ACCEPTAREA CONDIȚIILOR",
          contents: "Prin accesarea acestui site web si/sau a oricarei pagini a acestuia sunteti de acord cu aceste conditii de utilizare. " +
              "Daca nu sunteti de acord cu acesti termeni si conditii de utilizare, nu accesati acest site."
        },
        {
          title: "TERMENI UTILIZATI",
          contents: "Site = pagina web testeautoexplicate.ro, cu toate aplicațiile și secțiunile acesteia;\n" +
              "\n" +
              "Utilizator = orice persoană fizică, în vârstă de cel puțin 18 ani, sau persoană juridică, care accesează site-ul testeautoexplicate.ro;\n" +
              "\n" +
              "Utilizator Gratuit = orice Utilizator înregistrat pe testeautoexplicate.ro;\n" +
              "\n" +
              "Utilizator Premium = orice Utilizator ce a achizitionat acces premium pe testeautoexplicate.ro;\n" +
              "\n" +
              "Chestionar =  reprezintă o colectie de întrebari de legislatie rutieră;\n" +
              "\n" +
              "Platforma Învățare =  reprezinta totalitatea aplicatiilor web prezente pe testeautoexplicate.ro (mod învatare, teste auto, legislție rutieră, întrebările mele...etc.);\n" +
              "\n" +
              "Termene și condiții = prezentul contract de utilizare la distanță, definit în OUG 34/2014, art. 2, alin. 7."

        },
        {
          title: "DATE COMERCIANT",
          contents: "HEREA D. ION INTREPRINDERE INDIVIDUALĂ, Sat Puscasi, Nr. 23, Judetul Prahova, CUI 23468834"
        },
        {
          title: "DESCRIEREA SERVICIILOR",
          contents: "testeautoexplicate.ro este un site ce contine teste (chestionare) si legislatie rutieră explicate " +
              "pentru categoriile B și Redobândire cu scopul de a pregati utilizatorii pentru examele auto teoretice."
        },
        {
          title: "ACCESS SI ÎNREGISTRARE",
          contents: "Site-ul testeautoexplicate.ro este destinat persoanelor fizice și/sau juridice și/sau juridice cu vârsta de cel puțin 18 ani și capacitate deplină de exercițiu.\n" +
              "Pentru a putea accesa platforma de învatare este necesar ca utilizatorul sa îsi creeze un cont.  " +
              "Prin înregistrare, utilizatorul confirmă că are cel puțin 18 ani. " +
              "Procedura de înregistrare este gratuită, iar Utilizatorul va fi denumit Utilizator Gratuit. \n \n" +
              "Utilizatorii gratuiți au access limitat la platforma iar limitarile sunt vizibile pe platforma de învățare. \n \n"+
              "Datele de autentificare sunt confidențiale (email si parolă). Nu suntem răspunzători pentru utilizarea neglijentă a contului dumneavoastră " +
              "sau pentru înstrăinarea datelor acestuia, pe care sunteți obligat să le păstrați confidențiale și să nu le înstrăinați. " +
              "testeautoexplicate.ro își rezervă dreptul de a dezactiva conturile înstrăinate."

        },
        {
          title: "ABONAMENTE SI UTILIZATORII PREMIUM",
          contents:  "Site-ul testeautoexplicate.ro ofera trei tipuri de abonamente Premium. " +
              "Abonament Premium pe 30 de zile, Abonament Premium pe 60 de zile si Abonament Premium pe 120 de zile. " +
              "Toate abonamente premium oferă acces complet asupra platformei de învățare.\n\n " +
              "Pentru a obține titlul de Utilizator Premium, utilizatorul trebuie să achizitioneze un abonament premium prin intermediul testeautoexplicate.ro. " +
              "Utilizatorul poate plăti prin intermediul EuPlatesc.ro atât prin card bancar cat si prin SMS.\n \n" +
              "Utilizatorul va primi acces premium (si titlul de Utilizator Premium) in maximum 4 ore după confirmarea plătii de catre EuPlatesc.ro. " +
              "Dacă plata a fost confirmată dar utilizatorul nu a primit acces premium, " +
              "acesta va fi compensat cu prelungirea abonamentului cu cel putin 10% din numărul de zile alocat abonamentului inițial.\n \n" +
              "Dacă plata a fost confirmată, dar utilizatorul nu a primit acces premium, acesta poate notifica testeautoexplicate.ro prin intermediul mijloacelor " +
              "de comunicare disponibile pe site in maximum 120 de ore de la confirmarea plătii."

        },
        {
          title: "INFORMAȚII DESPRE PLĂȚILE PRIN CARD DE DEBIT/CREDIT",
          contents:  "Site-ul testeautoexplicate.ro nu este responsabil de securitatea datelor dumneavoastră bancare in timpul achizitionarii abonamentelor," +
              " securitatea acestora depinzand de procesatorul de plăti EuPlatesc.ro."

        },
        {
          title: "LIMITAREA RĂSPUNDERII",
          contents: "Deși echipa testeautoexplicate.ro se străduiește sa va ofere cele mai bune servicii, nu putem garanta perfectiunea acestor servicii. " +
              "Nu vă putem garanta ca testeautoexplicate.ro va fi disponibil neîntrerupt si fară erori. În cazul în care testeautoexplicate.ro nu este disponibil " +
              "pe o perioada mai lunga de 15% din perioada abonamentului premium aferent unui Utilizator Premium, Utilizatorul Premium va primi o prelungire a acestui " +
              "abonament cu cel putin 10% din numărul de zile alocat abonamentului inițial."+
              "\n \n" +
              "În nici un caz, site-ul testeautoexplicate.ro nu va fi răspunzător pentru orice daune directe, indirecte, incidentale sau de consecințele ce rezultă din lipsa de acuratețe a conținutului, erori, pierderi sau stricăciuni de orice fel apărute în urma utilizării site-ului testeautoexplicate.ro."

        },
        {
          title: "COOKIES",
          contents: "Site-ul testeautoexplicate.ro utilizeaza cookies (de tip esential) doar cu scopul de a autentifica si autoriza utilizatorul dar si pentru a salva progresul acestuia in cadrul site-ului. \n \n " +
              "Site-ul testeautoexplciate.ro nu poate functiona fara utilizarea cookies-urilor mentionate mai sus. Utilizarea site-ului testeautoexplicate.ro reprezinta acceptul dumneavoastra in utilizarea acestor cookies."

        },
        {
          title: "POLITICA DE ANULARE PREMIUM",
          contents: "Utilizatorul Premium isi poate anula abonamentul și îsi poate primi banii înapoi daca notifică testeautoexplicate.ro in termen de 24 de ore de la începerea abonamentului Premium prin intermediul mijloacelor de comunicare disponibile pe site."
        },
        {
          title: "DREPTURI DE AUTOR",
          contents: "Elementele postate pe site în totalitatea lor (conținut, text, grafică, metode de învățare, teste auto (chestionare), explicații, cod rutier (explicat)... etc.) aparțin în exclusivitate deținătorului site-ului, drepturile asupra lor fiind protejate."+
              "\n \n" +
              "Accesul neautorizat, distribuția, reproducerea, copierea, retransmiterea, publicarea, vânzarea, utilizarea comercială sub orice formă sau oricare alt tip de transfer a oricărei părți din site-ul testeautoexplicate.ro sunt, prin prezenta, strict interzise."

        },
        {
          title: "DISPOZIȚII FINALE",
          contents: "Prezentele Condiții Generale se pot modifica oricând fără o notificare prealabilă, în scopul armonizării cu legislația în vigoare."+
              "\n \n" +
              "Pentru orice conflict, părțile vor utliliza de toate posibilitățile de conciliere amiabilă. În cazul in care concilierea amiabilă nu poate rezolva conflictul, părțile vor apela la instanțele judecătorești competente."

        }
      ]
    }
  },
  computed: {},
  methods: {},
  mounted() {
  }
}
</script>
