<template>
  <div class="my-questions">

    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-card rounded elevation="5">
          <v-card-text class="text-center subtitle-1">
            Mai jos poți vizualiza întrebările tale si răspunsurile profesorilor la acestea. Puteți vizualiza statusul
            întrebarii in partea de stânga jos pentru fiecare întrebare.
          </v-card-text>
          <v-card-text class="subtitle-2 text-center font-weight-light">(Fiecare întrebare este publicata in mod anonim
            -
            cel ce va răspunde la întrebari nu are access la detaliile celui ce a pus întrebarea)
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--    Filter Button  -->
    <v-row class="mx-3 my-5" v-if="filteredComments.length > 0">
      <v-col cols="3">
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn small text color="black" @click="triggerAnsweredFilter()" v-on="on">
              <v-icon left>fa-filter</v-icon>
              <span>Filtrare Întrebari</span>
            </v-btn>
          </template>
          <span v-if="this.filterStatus === 'ANSWERED'">Afiseaza toate întrebarile.</span>
          <span v-else>Afisează doar întrebarile pentru care ai primit răspuns.
         </span>
        </v-tooltip>
      </v-col>

      <!--      <v-col cols="3">-->
      <!--        <v-tooltip top>-->
      <!--          <template v-slot:activator="{on}">-->
      <!--            <v-btn small text color="black" @click="triggerUnansweredFilter()" v-on="on">-->
      <!--              <v-icon left>fa-filter</v-icon>-->
      <!--              <span>Filtrare Întrebari</span>-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--          <span v-if="this.filterStatus === 'ONLY_UNANSWERED'">Afisează toate întrebarile.</span>-->
      <!--          <span v-else>Afiseaza doar întrebarile fara răspuns.</span>-->
      <!--        </v-tooltip>-->
      <!--      </v-col>-->
    </v-row>

    <v-row v-if="!loading && filteredComments && filteredComments.length === 0" justify="center">

      <v-col class="text-center">
        <v-card shaped>
          <v-card-subtitle class="text-center text-break justify-center">Nu ai pus nici o intrebare pana acum! Poți pune
            intrebari in sectiunea "Mod de învatare" sau "Chestionare Auto"!
          </v-card-subtitle>

          <v-card-actions class="my-5">
            <v-row justify="space-between">
              <v-col cols="6" class="text-center">
                <v-btn elevation="2" color="primary"
                       light
                       rounded
                       to="/invatare">
                  <v-icon :left="$vuetify.breakpoint.smAndUp"
                          size="25">mdi-school
                  </v-icon>
                  <span class="text-center" v-if="$vuetify.breakpoint.smAndUp">
                  Mod de învatare</span>
                </v-btn>
              </v-col>

              <v-col cols="6" class="text-center">
                <v-btn elevation="2" color="primary"
                       light
                       rounded
                       to="/chestionare">
                  <v-icon :left="$vuetify.breakpoint.smAndUp"
                          size="25">mdi-book-edit
                  </v-icon>
                  <span class="text-center" v-if="$vuetify.breakpoint.smAndUp">Chestionare</span>


                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!--    The actual Comments  -->
    <v-row v-for="comment in filteredComments" :key="comment.id">

      <v-col cols="12">

        <v-slide-y-transition>
          <QuestionnaireAndQuestion :comment="comment" class="my-5" v-show="true"/>
        </v-slide-y-transition>

      </v-col>
    </v-row>

    <!--    The add more questions button  -->
    <v-row v-if="!fullRetrievedComments && comments.length !== 0" justify="center">
      <v-col cols="12" class="text-center">
        <v-btn large color="primary" rounded :loading="loading" @click="loadMoreComments">
          <v-icon large left>mdi-chevron-down</v-icon>
          Încarca mai multe întrebari
        </v-btn>
      </v-col>
    </v-row>

    <!--    Loading Row    -->
    <v-row v-if="loading" justify="center" class="my-10">
      <v-col cols="12" class="text-center"><span class="font-weight-bold">Intrebarile tale se încarca</span></v-col>
      <v-col cols="12" class="text-center">
        <!--    Loading Progress   -->
        <v-progress-circular
            indeterminate
            size="80"
            color="amber"
            :width="5"
        ></v-progress-circular>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import QuestionnaireAndQuestion from "../components/Questions/QuestionnaireAndQuestion";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'MyQuestions',
  components: {QuestionnaireAndQuestion},
  data() {
    return {
      page: 0,
      loading: true,
      filterStatus: '',
      deleteConfirmationDialog: true
    }
  },
  methods: {
    ...mapActions('app', ['getComments', 'deleteComments', 'deleteCommentsById', 'markQuestionAsAnswered']),
    loadMoreComments() {
      this.loading = true
      this.getComments(this.page++)
    },
    triggerAnsweredFilter() {
      if (this.filterStatus === 'ANSWERED')
        this.filterStatus = ''
      else
        this.filterStatus = 'ANSWERED'
    },
    triggerUnansweredFilter() {
      if (this.filterStatus === 'ONLY_UNANSWERED')
        this.filterStatus = ''
      else
        this.filterStatus = 'ONLY_UNANSWERED'
    }
  },
  computed: {
    ...mapGetters('app', ['comments', 'fullRetrievedComments', 'getAnswerCommentsStatus', 'getDeleteCommentStatus']),
    //filter the comments
    filteredComments() {
      if (this.filterStatus === 'ANSWERED') {
        return this.comments.filter((answer) => answer.active)
      } else if (this.filterStatus === 'ONLY_UNANSWERED')
        return this.comments.filter((answer) => !answer.active)

      return this.comments
    }
  },
  async mounted() {
    //activate the loading

    //reset the comments and get the first page of the comments
    await this.deleteComments()
    this.loading = true
    //dont put async (we don't use the result for any further processing)
    this.getComments(this.page++)

  },
  watch: {
    fullRetrievedComments(newValue) {
      this.loading = false
      if (newValue === true) {
        if (this.comments && this.comments.length > 0)
          this.$toast.warning('Toate intrebările tale au fost încarcate!', '', {position: "topCenter"});
      }
    },
    // eslint-disable-next-line no-unused-vars
    comments(newValue) {
      this.loading = false
    },
    async filteredComments() {
      if (this.filterStatus === 'ANSWERED')
        while (!this.fullRetrievedComments && this.filteredComments.length === 0)
          await this.getComments(this.page++)
    },
    getAnswerCommentsStatus(newValue) {
      if (newValue.status === 'success') {
        //mark the question as answered
        this.markQuestionAsAnswered(newValue.id)

        this.$toast.success(newValue.message, '', {position: "topCenter"});
      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});

      this.loading = false
    },
    getDeleteCommentStatus(newValue) {
      if (newValue.status === 'success') {
        //delete the confirmed deleted comment
        this.deleteCommentsById(newValue.id)

        this.$toast.success(newValue.message, '', {position: "topCenter"});
      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});
    }
  }
}
</script>
