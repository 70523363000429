<template>
  <div>
    <v-card class="ma-0 pa-0" flat rounded color="transparent">
      <!--      Status image  -->
      <v-card-text class="justify-center ma-0 pa-0">
        <v-row justify="center">
          <div>

            <v-img v-if="status === 'success'" contain
                   src='@/assets/payment/utilizator_premium_success.png'
                   lazy-src='@/assets/payment/utilizator_premium_success.png'
                   @click="startClicked = true" :max-width="imageSize"></v-img>
            <v-img v-else-if="status === 'fail'" contain
                   src='@/assets/payment/utilizator_premium_fail.png'
                   lazy-src='@/assets/payment/utilizator_premium_fail.png'
                   @click="startClicked = true" :max-width="imageSize"></v-img>
            <v-img v-else contain
                   src='@/assets/logos/logo_medium.png'
                   lazy-src='@/assets/logos/logo_medium.png'
                   @click="startClicked = true" :max-width="imageSize"></v-img>

          </div>

          <!--   Info about payment -->
          <v-card-text>
            <v-card>
              <v-card-title class="text-break">
                <v-row justify="center">
                  <v-col cols="12" class="text-center">
                    <span
                        v-if="status === 'success'">Felicitări! Acum esti utilizator <span
                        class="sea_orange--text font-weight-bold clickable"
                        @click="$router.push('/premium')">premium</span>: {{
                        $route.query.email
                      }}</span>
                    <span v-else-if="status === 'fail'">Din pacate ceva nu a mers bine pentru a activa abonamentul <span
                        class="sea_orange--text font-weight-bold clickable"
                        @click="$router.push('/premium')"> premium </span> pentru: {{ $route.query.email }}</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle class="text-break">
                <v-row justify="center">
                  <v-col cols="12" class="text-center">
                    <span v-if="status === 'success'">Abonamentul tău <span
                        class="sea_orange--text font-weight-bold clickable"
                        @click="$router.push('/premium')"> premium </span> a fost activat! Verifica in sectiunea "Profilul Meu" daca contul tau este premium. In maximum 10 minute contul premium se va activa. Daca nu este cazul, contactați echipa de suport in sectiunea "Raporteaza o problemă"!  </span>
                    <span v-else-if="status === 'fail'"><span class="error--text font-weight-bold">{{this.message}}</span><br> Verifică daca ai fonduri suficiente pe cont sau daca banca ta a refuzat plata. Dacă ai fonduri suficiente, și banca ta nu a refuzat plata, încarca din nou mai târziu sau contactați echipa de suport in sectiunea "Raporteaza o problemă"!</span>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-card-text>
        </v-row>
      </v-card-text>

      <v-card-actions class="my-5">
        <v-row justify="space-between">
          <v-col cols="6" class="text-center">
            <v-btn elevation="2" color="primary"
                   light
                   x-large
                   rounded
                   to="/">
              <v-icon :left="$vuetify.breakpoint.smAndUp"
                      size="30">mdi-home
              </v-icon>
              <span class="text-center" v-if="$vuetify.breakpoint.smAndUp">Pagina principală</span>


            </v-btn>
          </v-col>

          <v-col cols="6" class="text-center">
            <v-btn elevation="2" color="primary"
                   light
                   x-large
                   rounded
                   to="/profil">
              <v-icon :left="$vuetify.breakpoint.smAndUp"
                      size="30">mdi-account
              </v-icon>
              <span class="text-center" v-if="$vuetify.breakpoint.smAndUp">Profilul meu</span>


            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="sass" scoped>
</style>
<script>

export default {
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    status() {
      //success e.g.             http://testeautoexplicate.ro/premium/success?amount=1.00&curr=RON&invoice_id=89&ep_id=E01B8B23C0298D76FE0DD719AD8F5287158D19A9&merch_id=44840996788&action=0&message=Approved&approval=tstcrd&timestamp=20211107130256&nonce=3fabd2cb4e396407d2f800bcfdf75f6d&email=marcel123%40gmail.com&fp_hash=1F916D284CE33329A65426ACF5202781&ExtraData%5Bep_method%5D=get&ExtraData%5Bbacktosite_method%5D=get&gatewayoption=1
      //failed expired card e.g. http://testeautoexplicate.ro/premium/fail?amount=1.00&curr=RON&invoice_id=91&ep_id=BBE2537444C6414D6CB45E46A7AFF5E6AB4E89A6&merch_id=44840996788&action=3&message=IP_PRT&approval=&timestamp=20211107130452&nonce=3a91f6d7bc2a548c73711b3ea9a22c12&email=marcel123%40gmail.com&fp_hash=DD4FB28BAF9014B323E4561CA93E2E74&ExtraData%5Bep_method%5D=get&ExtraData%5Bbacktosite_method%5D=get&gatewayoption=1
      //failed NO funds e.g.     http://testeautoexplicate.ro/premium/fail?amount=1.00&curr=RON&invoice_id=106&ep_id=8BF906D36722EC84FB20055830FFC70EA84D2CF4&merch_id=44840996788&action=3&message=Not+sufficient+funds&approval=&timestamp=20211108200000&nonce=1e60e5cdc0452aac2b540d12b0329dcc&email=marcel123%40gmail.com&fp_hash=9E719FB91DB05D6AFB2127813E688F86&ExtraData%5Bep_method%5D=get&ExtraData%5Bbacktosite_method%5D=get&gatewayoption=1
      //failed expired card:     http://testeautoexplicate.ro/premium/fail?amount=1.00&curr=RON&invoice_id=105&ep_id=050DE823C0F0AB8F0954695513A3F7D97326B893&merch_id=44840996788&action=3&message=Expired%20card&approval=&timestamp=20211108195831&nonce=e9eeafd480521a862aee0d95603ef772&email=marcel123%40gmail.com&fp_hash=22032FB29D2702C0DC859A6A32ABFDEA&ExtraData%5Bep_method%5D=get&ExtraData%5Bbacktosite_method%5D=get&gatewayoption=1
      return this.$route.params.status
    },
    imageSize() {
      if (this.$vuetify.breakpoint.name === 'xs') {
        if (window.innerWidth < 300)
          return 280

        return 300
      }

      return 500
    },
    message() {
      switch (this.$route.query.message) {
        case 'Authentication failed':
          return 'Autentificare Nereusita'
        case 'Not sufficient funds':
          return 'Fonduri Insuficiente. Verifică-ți foundurile de pe cont! '
        case 'Transaction declined':
          return 'Tranzacție respinsă. Verifică daca banca ta a respins tranzacția! '
        case 'Expired card':
          return 'Cardul tău este expirat! Verifică valididatea cardului tau! '
        case 'Invalid response':
          return 'Răspunsul este invalid! (tranzacția ta nu poate fi procesată) '
        case 'Approved':
          return 'Tranzacție acceptata! '
        case 'SMS captured transaction':
          return 'Tranzacție SMS acceptata! '
        default:
          return ''
      }
    }
  },
  watch: {}
}
</script>

<style scoped lang="sass">
.clickable
  cursor: pointer !important
</style>
