<template>
  <div>
    <v-card v-if="finishInit">
      <v-data-table
          v-model="selectedItems"
          :loading="loading"
          :headers="headers"
          :items="loadedQuestions"
          class="elevation-1"
          no-data-text="Intrebarile nu au putut fi obtinute. Verificati daca exista intrebari pentru aceasta categorie de chestionar. Daca exista, reincarca pagina!"
          :footer-props="{itemsPerPageText:'Intrebari pe pagina:',
         pageText: '{0}-{1} din {2}' ,
        'items-per-page-options':[5,10, 15, 30, 100, -1]}"
          :items-per-page="5"
          :search="search"
          show-select>


        <!--      Header (including title)    -->
        <template v-slot:top>
          <v-toolbar flat>

            <!--        Search Field          -->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cautare"
                single-line
                hide-details
                class="mx-5"
            ></v-text-field>
          </v-toolbar>
        </template>


        <!--      Slot for Category   -->
        <template v-slot:item.category="{ item }">
          <v-chip color="sea_orange lighten-1" dark> {{ item.category }}</v-chip>
        </template>

        <!--      Slot for id (bold text)   -->
        <template v-slot:item.id="{ item }">
          <span class="font-weight-bold"> {{ item.id }}</span>
        </template>

        <!--      Image Template    -->
        <template v-slot:item.imageUrl="{ item }">
          <v-img :src="item.imageUrl" width="75px" height="75px" contain/>
        </template>
      </v-data-table>


      <!-- Buttons for Actions like save and cancel -->
      <v-card-actions>
        <!-- Display the remaining number of questions that can still be added -->
        <span class="font-weight-light">Mai poti adauga
          <span
              class="font-weight-bold">{{
              this.questionnaire.numberOfQuestions - this.selectedItems.length - this.addedQuestions.length
            }}
          </span>de intrebari.</span>

        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="$emit('closeDialog')"
        >
          Anuleaza
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="addSelectedQuestionsIntoQuestionnaire"
        >
          <div>Adauga Intrebari</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'SelectQuestions',
  props: {
    loading: Boolean,
    questionnaire: Object,
    addedQuestions: Array
  },
  data() {
    return {
      search: '',
      finishInit: false,
      selectedItems: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: true
        },
        {
          text: 'Categorie',
          value: 'category',
          sortable: true,
        },
        {
          text: 'Titlu',
          value: 'title',
          sortable: true
        },
        {
          text: 'Intrebare',
          value: 'question',
          sortable: true
        },
        {
          text: 'Imagine',
          value: 'imageUrl',
          sortable: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('admin', ['getQuestions', 'addQuestionIntoQuestionnaire']),
    //add all the questions into the app
    addSelectedQuestionsIntoQuestionnaire() {
      this.selectedItems.forEach((item) => this.addQuestionIntoQuestionnaire({
        questionnaire: this.questionnaire,
        question: item
      }))

      this.$emit('closeDialog')
    }
  },
  computed: {
    ...mapGetters('admin', ['questions']),
    loadedQuestions() {
      //FILTER BY CATEGORY
      let filteredQuestions = this.questions.filter(question => question.category === this.questionnaire.category)

      //filter it further by id only if there are any questions in it (questions with cat.b will be available only for cat. b app)
      if (this.questionnaire.questionsExpand.length !== 0) {
        //filter by already contained questions
        const alreadyExistingQuestionsId = this.questionnaire.questionsExpand.map(question => question.id)

        filteredQuestions = filteredQuestions.filter(question => alreadyExistingQuestionsId.indexOf(question.id) === -1);
      }

      return filteredQuestions
    }
  },
  async mounted() {
    await this.getQuestions()
    this.finishInit = true
  },
  watch: {
    selectedItems(newValue, oldValue) {
      if (this.questionnaire.numberOfQuestions - this.selectedItems.length - this.addedQuestions.length < 0)
        this.$nextTick(() => {
          this.selectedItems = oldValue
        })
    }
  }
}
</script>
