<template>
  <div>
    <AdCards></AdCards>
  </div>
</template>

<script>
import AdCards from "../components/Advertisements/AdCards";

export default {
  name: 'Books',
  components: {AdCards},
  props: {},
  data() {
    return {}
  },
  methods: {},
  computed: {}
}
</script>


<style scoped lang="sass">
</style>
