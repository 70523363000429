<template>
  <div>

    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="80"
          color="amber"
          :width="5"
      ></v-progress-circular>
    </v-overlay>

    <v-scale-transition>
      <v-row v-show="show" align="center" justify="center">
        <v-col cols="8" class="my-10">
          <!--        <span v-if="success" class="title success&#45;&#45;text text-uppercase">E-MAIL ACTIVAT CU SUCCESS!</span>-->
          <!--        <span v-else class="title error&#45;&#45;text text-uppercase">E-MAIL-UL NU A FOST ACTIVAT CU SUCCESS!</span>-->
        </v-col>
      </v-row>
    </v-scale-transition>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PasswordReset',
  props: {},
  components: {},
  data() {
    return {
      show: false,
      success: false,
      loading: false
    }
  },
  methods: {
    ...mapActions('auth', ['verifyEmail', 'retrieveUserData'])
  },
  computed: {
    ...mapGetters('auth', ['getEmailVerifyStatus', 'isUserLoggedIn'])
  },
  mounted() {
    this.loading = true
    let path = this.$route.fullPath
    this.verifyEmail(path.substring(path.lastIndexOf('/') + 1))
  },
  watch: {
    getEmailVerifyStatus(newValue) {
      if (newValue.status === 'success') {
        this.success = true

        //if successful retrieve the new user data
        if (this.isUserLoggedIn)
          this.retrieveUserData()

        this.$toast.success(newValue.message, '', {position: "topCenter"})
      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"})

      if (this.isUserLoggedIn)
        this.$router.push('/profil')
      else
        this.$router.push("/sign-up")

      this.loading = false
      this.show = true
    }
  }
}
</script>
