<template>
  <div id="app">
    <v-app>
      <Navbar/>
      <v-main class="sea_light_blue">
        <v-container class="my-5" fluid>
          <router-view>
          </router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>

import Navbar from "@/components/Navbar";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  components: {Navbar},
  data: () => ({
    //
  }),
  methods: {
    ...mapActions('auth', ['retrieveUserData', 'storeJWTToken'])
  }, computed: {
    ...mapGetters('auth', ['getJWTToken', 'getUserData'])
  },
  created() {
    this.storeJWTToken();

    if (this.getJWTToken) {
      this.retrieveUserData().then(() => {
        if (this.getUserData) {

          if (this.getUserData.name) {
            // eslint-disable-next-line no-unused-vars
            // this.$router.push('/').catch(err => {
            // });
            if (this.$router.currentRoute.path.split("/")[1] !== 'chestionar')
              this.$toast.success('Bine ai revenit ' + this.getUserData.name + "!", 'Salut!', {position: "topCenter"});
          }
        }
      })


    }
  }
};
</script>

<style>
html {
  overflow-y: auto
}
</style>
