<template>
  <v-card>
    <v-card-title class="title px-2 text-center justify-center">
      {{ formTitle }}
    </v-card-title>

    <v-card-text>
      <v-form class="" v-model="validForm" :disabled="loading">
        <v-text-field label="Subiect" v-model="title" prepend-icon="mdi-format-title"
                      :rules="titleRules"></v-text-field>
        <v-text-field label="Email" v-model="email" prepend-icon="mdi-email" :rules="emailRules"></v-text-field>
        <v-textarea label="Informatii Despre Problemă" v-model="content" prepend-icon="mdi-pencil-outline"
                    :rules="contentRules" counter></v-textarea>

        <v-row justify="end mt-3">
          <v-btn text class="success mx-0 mt-3" @click="submit" :disabled="!validForm" :loading="loading">
            {{ buttonText }}
            <v-icon right small>fa-paper-plane</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    formTitle: {
      default: 'Raportează o problemă'
    },
    buttonText: {
      default: 'Raportează problemă'
    }
  },
  data() {
    return {
      title: '',
      content: '',
      email: '',
      validForm: false,
      due: null,
      loading: false,
      titleRules: [
        (a) => a.length >= 3 || 'Subiectul trebuie sa aibă minim 3 caractere',
        (a) => a.length <= 75 || 'Subiectul trebuie sa aibă maxim 75 de caractere'

      ],
      emailRules: [
        (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase()) === true || 'Email-ul introdus nu este valid.',
      ],
      contentRules: [
        (a) => a.length >= 10 || 'Campul de informatii trebuie sa aibă minim 10 caractere',
        (a) => a.length <= 350 || 'Campul de informatii  trebuie sa aibă maxim 350 de caractere'
      ]
    }
  },
  methods: {
    ...mapActions('app', ['submitIssue']),
    submit() {
      this.loading = true
      this.submitIssue({email: this.email, title: this.title, info: this.content})
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData', 'isUserLoggedIn']),
    ...mapGetters('app', ['getSubmitIssueStatus']),
    formattedDate() {
      return this.due ? format(parseISO(this.due), 'do MMM yyyy') : ''
    }
  },
  mounted() {
    //prefill the email if the email is available
    if (this.isUserLoggedIn)
      this.email = this.getUserData.email
  },
  watch: {
    getSubmitIssueStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, 'Succes', {position: "topCenter"});
        this.email = ''
        this.content = ''
        this.title = ''
      } else if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {'position': "topCenter"});

      this.$emit('close-dialog')
      this.loading = false


    }
  }
}
</script>
