<template>
  <div>
    <v-row align="center" class="my-2" justify="center">
      <h1 class="title">
        <span class="text-h4 font-weight-bold text-center">Alege-ți Abonamenul</span>
      </h1>
    </v-row>

    <v-row align="center" class="my-2" justify="center">
      <span class="text-center font-weight-light">Invata fara limitari pe calculatorul sau telefonul dumneavoastra alegand Premium.</span>
    </v-row>

    <v-row align="stretch" justify="center">
      <v-col cols="12" md="4" v-for="card in premiumCards" :key="card.id">
        <PremiumCard
            :id="card.id"
            :color="card.color"
            :key="card.id"
            :title="card.title"
            :price="card.price"
            :priceSMS="card.priceSMS"
            :price-time="card.priceTime"
            :features="card.features"
            :loading="loading"
            :disabled="card.disabled"
            @buy-clicked="performRequest">
        </PremiumCard>
      </v-col>
    </v-row>

    <v-dialog v-model="showChoice" max-width="750px" transition scrollable overlay-opacity="0.7">
      <v-container>
        <v-row justify="space-around" align="stretch" class="mx-auto ma-auto">
          <v-col cols="12" md="5" class="d-flex">
            <v-card shaped color="primary lighten-4" hover class="flex-grow-1"
                    @click="performRequestData(paymentChoice.id,'')" :loading="loading" :disabled="loading">
              <v-card-title class="justify-center text-center font-weight-bold">
                Plata prin Card Bancar
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon size="70" color="black">fa-credit-card</v-icon>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="5" class="d-flex">
            <v-card shaped color="primary lighten-4" hover class="flex-grow-1"
                    @click="performRequestData(paymentChoice.id,'SMS')" :loading="loading" :disabled="loading">
              <v-card-title class="justify-center text-center font-weight-bold">
                Plata prin SMS
              </v-card-title>
              <v-card-text class="text-center justify-center">
                <v-img max-width="200" src='@/assets/payment/logo-sms.webp' class="mx-auto"></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" align="stretch" class="mx-auto ma-auto text-center">
          <v-col cols="12" class="d-flex text-center justify-center">
            <v-card shaped>
              <v-card-text class="text-center justify-center">
                <span class="error--text font-weight-bold">Atentie!</span> Dacă alegeți plata prin SMS, in portalul de
                plată alegeți "Plata Prin SMS" <span class="font-weight-bold"> NU </span>"Card bancar" pentru a evita
                consturile suplimentare.<br> Pentru plata prin Card Bancar <span class="font-weight-bold">NU</span> se percepe TVA, dar pentru plata prin SMS <span class="font-weight-bold">se percepe</span> TVA.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<style lang="sass" scoped>
</style>
<script>
import PremiumCard from "../components/Premium/PremiumCard";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {PremiumCard},
  data() {
    return {
      disabled: false,
      loading: false,
      showChoice: false,
      paymentChoice: null,
      premiumCards: [
        {
          id: 'PREMIUM30',
          color: 'sea_orange',
          title: 'Premium',
          price: 8,
          priceSMS: 5,
          priceTime: '30',
          features: ['Access complet la chestionare explicate',
            'Access complet la legislatie explicata',
            'Poti adresa intrebari direct profesorilor de legislatie rutiera'],
          disabled: false
        },
        {
          id: 'PREMIUM60',
          color: 'sea_orange',
          title: 'Premium',
          price: 9,
          priceSMS: 6,
          priceTime: '60',
          features: ['Access complet la chestionare explicate',
            'Access complet la legislatie explicata',
            'Poti adresa intrebari direct profesorilor de legislatie rutiera'],
          disabled: false
        },
        {
          id: 'PREMIUM120',
          color: 'sea_orange',
          title: 'Premium',
          price: 10,
          priceSMS: 7,
          priceTime: '120',
          features: ['Access complet la chestionare explicate',
            'Access complet la legislatie explicata',
            'Poti adresa intrebari direct profesorilor de legislatie rutiera'],
          disabled: false
        },
        {
          id: 'FREE',
          color: 'green',
          title: 'Gratuit',
          price: 0,
          priceSMS: 0,
          priceTime: '∞',
          features: ['Access limitat la chestionare',
            'Access limitat la legislatie'],
          disabled: true
        }
        //15 lei pt. 30 zile, 20 lei pt. 60 zile, 30 lei pt. 120 zile
      ]
    }
  },
  methods: {
    ...mapActions('premium', ['requestPaymentGeneratedLink']),
    performRequest(id) {
      this.showChoice = true
      this.paymentChoice = {id: id}
    },
    performRequestData(id, option) {
      if (!this.paymentChoice)
        return;

      this.loading = true

      if (id !== 'FREE')
        this.requestPaymentGeneratedLink(this.paymentChoice.id + option)
      else {
        this.loading = false

      }
    }
  }
  ,
  computed: {
    ...
        mapGetters('premium', ['getPaymentGeneratedLink']),
    ...
        mapGetters('app', ['isQuestionFeatureDisabled'])
  }
  ,
  beforeMount() {
    //if the question feature is disabled remove the talks about asking a professor
    if (this.isQuestionFeatureDisabled) {
      this.premiumCards.forEach((el) => {
        if (el.title === 'Premium')
          el.features.splice(2, 1);
      })
    }

  }
  ,
  watch: {
    getPaymentGeneratedLink(newValue) {
      if (newValue.status === 'fail') {
        this.loading = false
        this.$toast.error('Redirectionarea către platforma de plăți nu a functionat. Încearca din nou mai târziu!', 'Premium', {position: "topCenter"});
      } else if (newValue.status === 'success') {
        //redirect to the payment url
        window.location.href = newValue.url
        this.loading = false
      }
    }
  }
}
</script>
