<template>
  <div>
    <v-data-table :loading="loading"
                  :headers="headers"
                  :items="tableData"
                  class="elevation-1"
                  :show-expand="this.mode === 'Questionnaires'"
                  :no-data-text="noDataText"
                  :no-results-text="translate(this.mode) + ' Negasite'"
                  :footer-props="{itemsPerPageText: footerItemsPerPageText,
         pageText: '{0}-{1} din {2}' ,
        'items-per-page-options':[5,10, 15, 30, 100, -1],
        }"
                  :items-per-page="5"
                  :search="search"
                  @item-expanded="retrieveQuestionnaireQuestions"
                  single-expand
    >

      <!--      Header (including title)    -->
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>

          <!--        Search Field          -->
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cautare"
              single-line
              hide-details
              class="mx-5"
          ></v-text-field>


          <v-dialog
              v-model="dialog"
              max-width="1600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="newEntity"
              >
                {{ addButtonText }}
              </v-btn>
            </template>

            <v-card rounded>
              <!--              <v-card-title>-->
              <!--                <span class="text-h5">{{ title }}</span>-->
              <!--              </v-card-title>-->
              <v-card-text>
                <v-container :key="popUpKey">
                  <div v-if="mode === 'Questionnaires'">
                    <QuestionnaireEdit ref="questionnaireEdit" :title="title" :headers="headers"
                                       :questionnaire="questionnaire"
                                       :questions="questionnaire.questionsExpand"
                                       :loading="questionnaireQuestionsLoading"
                                       @updateQuestions="updateQuestionnaireQuestions(questionnaire)"></QuestionnaireEdit>
                  </div>
                  <div v-else-if="mode === 'Questions'">
                    <Questionnaire ref="questionEdit" :edit-mode="true" :visibleExplanation="true"
                                   :question="question"></Questionnaire>
                  </div>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDialog"
                >
                  Anuleaza
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="saveEditActionClicked"
                >
                  <div v-if="addEditFlag === 'ADD'">Salveaza</div>
                  <div v-else>Editeaza</div>
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!--      Checkboxes for answers the A,B,C -->
      <template v-for="slot in responsesSlots" v-slot:[`item.${slot}`]="{item}">
        <v-checkbox
            :key="slot"
            v-model="item[slot]"
            :readonly="true"
        ></v-checkbox>
      </template>

      <!--      Image Template    -->
      <template v-slot:item.imageUrl="{ item }">
        <v-img :src="item.imageUrl" width="75px" height="75px" contain/>
      </template>


      <!--      Role (Availability) template    -->
      <template v-slot:item.visibility="{ item }">
        <v-chip v-if="item.visibility" small :class="`${item.visibility} white--text caption my-2`">
          {{ translate(item.visibility) }}
        </v-chip>
      </template>

      <!--      Slot for active (switches) -->
      <template v-slot:item.active="{ item }">
        <v-switch
            v-model="item.active"
            :readonly="true"
        ></v-switch>
      </template>

      <!--      Slot for id (bold text)   -->
      <template v-slot:item.id="{ item }">
        <span class="font-weight-bold"> {{ item.id }}</span>
      </template>

      <!--      Slot for Category   -->
      <template v-slot:item.category="{ item }">
        <v-chip color="sea_orange lighten-1" dark> {{ item.category }}</v-chip>
      </template>

      <!--      Slot for extra items (expand) -->
      <template v-if="mode === 'Questionnaires'" v-slot:expanded-item="{ headers, item }">
        <div :key="expandId">
          <v-list color="transparent">
            <template v-for="(question,index) in item.questionsExpand">
              <v-list-item color="transparent" dense :key="index" v-if="question.length !== 0">
                <p class="font-weight-bold">{{ question.id }}) &nbsp;</p>
                <p class="font-weight-light"> ({{ question.title }}) &nbsp;</p>
                <p>{{ question.question }}</p>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </template>

      <!--      Actions (like edit,delete)  -->
      <template v-slot:item.actions="{ item }">
        <v-icon
            class="mb-2"
            @click="editItem(item)"
            color="primary lighten-1"
        >
          mdi-pencil
        </v-icon>


        <v-icon
            class="mb-2"
            @click="deleteItemConfirm(item)"
            color="primary lighten-1">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!--        Delete Dialog Confirmation    -->
    <v-dialog
        v-model="deleteConfirmationDialog"
        max-width="320"
        :retain-focus="false">

      <ConfirmationCard
          title="Sterge"
          :text="'Doresti sa stergi entitatea cu titlul: \n' + selectedItem.id + ') ' + selectedItem.title"
          button-one-text="Sterge"
          button-one-icon="mdi-delete"
          button-two-text="Anuleaza"
          button-two-icon="mdi-close-octagon"
          @button-one-click="deleteItem"
          @button-two-click="closeDeleteDialog"/>

    </v-dialog>
  </div>
</template>

<script>

import Questionnaire from "./Questionnaires/Questionnaire";
import {mapActions, mapGetters} from "vuex";
import ConfirmationCard from "./ConfirmationCard";
import axios from "axios";
import QuestionnaireEdit from "./QuestionnaireEdit";

export default {
  name: 'AdminResourceEdit',
  props: {
    tableData: Array,
    loading: Boolean,
    mode: String
  },
  components: {QuestionnaireEdit, ConfirmationCard, Questionnaire},
  data() {
    return {
      keys: null,
      deleteConfirmationDialog: false,
      formTitle: 'Titlu',
      dialog: false,
      noDataText: 'Alege una dintre optiunile de mai sus!',
      sortConditions: [],
      selectedItem: {},
      search: '',
      popUpKey: 0,
      addEditFlag: 'ADD',
      //name them accordingly to the backend response
      responsesSlots: ['a', 'b', 'c'],
      expandId: 0,
      questionnaireQuestionsLoading: false
    }
  },
  methods: {
    async retrieveQuestionnaireQuestions(obj) {
      this.questionnaireQuestionsLoading = true

      //when expand is clicked for questionnaires display the questions in a app
      if (obj.value && this.mode === 'Questionnaires') {
        try {
          const response = await axios.get('https://api.testeautoexplicate.ro/questionnaires/' + obj.item.id, {
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ` + this.getJWTToken
            }
          })

          obj.item.questionsExpand = [...response.data.payload.questions]

          //re-render the expand component when info is received
          this.expandId++
          this.questionnaireQuestionsLoading = false

        } catch (error) {
          this.questionnaireQuestionsLoading = false
          this.$toast.error('Intrebarile Chestionarului cu ID: ' + obj.item.id + ' nu exista sau nu au putut fi gasite! Adauga intrebari in acest chestionar!', 'Eroare', {position: "topCenter"})
        }
      }
    },
    initialize() {
    },
    closeDialog() {
      this.dialog = false
    },
    deleteItemConfirm(item) {
      //delete confirmation
      this.deleteConfirmationDialog = true
      this.selectedItem = item
    },
    editItem(item) {
      this.addEditFlag = 'EDIT'
      this.popUpKey++
      this.dialog = true
      this.selectedItem = item

      if (this.mode === 'Questionnaires') {
        this.retrieveQuestionnaireQuestions({item: this.selectedItem, value: true})
      }
    },
    deleteItem() {
      this.deleteConfirmationDialog = false
      if (this.mode === 'Questions') {
        this.deleteQuestion(this.selectedItem.id)
      } else if (this.mode === 'Questionnaires') {
        this.deleteQuestionnaire(this.selectedItem.id)
      }
    },
    closeDeleteDialog() {
      this.deleteConfirmationDialog = false
    },
    translate(string) {
      switch (string) {
        case 'question':
          return 'Intrebare'
        case 'answerA':
          return 'Raspunsul A'
        case 'answerB':
          return 'Raspunsul B'
        case 'answerC':
          return 'Raspunsul C'
        case 'explanation':
          return 'Explicatie'
        case 'title':
          return 'Titlu'
        case 'imageUrl':
          return 'Imagine'
        case 'category':
          return 'Categorie Auto'
        case 'a':
          return 'Raspunsul Corect A'
        case 'b':
          return 'Raspunsul Corect B'
        case 'c':
          return 'Raspunsul Corect C'
        case 'id':
          return 'ID'
        case 'active':
          return 'Activat'
        case 'visibility':
          return 'Vizibilitate'
        case 'PAID_USER':
          return 'User Premium'
        case 'ADMIN':
          return 'Administrator'
        case 'FREE_USER':
          return 'Utilizator Gratuit'
        case 'numberOfQuestions':
          return 'Numar Intrebari Categorie'
        case 'Questionnaires':
          return 'Chestionare'
        case 'Questions':
          return 'Intrebari'
        default:
          return string
      }
    },
    newEntity() {
      switch (this.mode) {
        case 'Questions':
          this.newQuestion()
          break
        case 'Questionnaires':
          this.newQuestionnaire()
          break
      }
    },
    newQuestionnaire() {
      this.popUpKey++
      this.selectedItem = {
        active: '',
        title: '',
        category: ''
      }
      this.addEditFlag = 'ADD'
    },
    newQuestion() {
      this.popUpKey++
      this.addEditFlag = 'ADD'

      this.selectedItem = {
        a: false,
        b: false,
        c: false,
        answerA: '',
        answerB: '',
        answerC: '',
        explanation: '',
        title: '',
        category: ''
      }
    },
    ...mapActions('admin', ['addQuestion', 'editQuestion', 'deleteQuestion', 'addQuestionnaire', 'editQuestionnaire', 'deleteQuestionnaire']),
    addQuestionFromNewQuestionnaire() {
      this.$refs.questionEdit.enableWaitResponse()
      this.addQuestion(this.$refs.questionEdit.packUpQuestion())
    },
    addNewQuestionnaire() {
      this.$refs.questionnaireEdit.enableWaitResponse()
      this.addQuestionnaire(this.$refs.questionnaireEdit.packUpQuestionnaire())
    },
    editCurrentQuestion() {
      this.$refs.questionEdit.enableWaitResponse()
      this.editQuestion(this.$refs.questionEdit.packUpQuestion(true))
    },
    editCurrentQuestionnaire() {
      this.$refs.questionnaireEdit.enableWaitResponse()
      this.editQuestionnaire(this.$refs.questionnaireEdit.packUpQuestionnaire(true))
    },
    saveEditActionClicked() {
      if (this.addEditFlag === 'ADD') {
        if (this.mode === 'Questions')
          this.addQuestionFromNewQuestionnaire()
        else if (this.mode === 'Questionnaires')
          this.addNewQuestionnaire()
      } else if (this.addEditFlag === 'EDIT') {
        if (this.mode === 'Questions')
          this.editCurrentQuestion()
        else if (this.mode === 'Questionnaires') {
          this.editCurrentQuestionnaire()
        }
      }

    },
    calculateHeaders(tableData) {
      if (tableData) {
        let res = []

        tableData.forEach((el) => {
          el.questionsExpand = []
        })

        Object.keys(this.tableData[0]).forEach((el) => el !== 'id' && el !== 'questionsExpand' ? res.push({
          text: this.translate(el),
          value: el,
          sortable: true,
        }) : '')

        res.sort((a, b) => a.text.localeCompare(b.text))

        //PUT THE INDEX FIRST
        res.splice(0, 0, {
          text: this.translate('id'),
          value: 'id',
          sortable: true,
        });

        //ADD Actions (like edit/delete) at the end
        res.push({text: 'Actiuni', value: 'actions', sortable: false})

        //allow expands if we have questionnaires
        if (this.mode === 'Questionnaires')
          res.push({text: '', value: 'data-table-expand', sortable: false})


        return res
      }
      return []
    },
    updateQuestionnaireQuestions(questionnaire) {
      //update when data changes
      this.retrieveQuestionnaireQuestions({item: questionnaire, value: true})
    }
  },
  computed: {
    ...mapGetters('admin', ['getAddQuestionStatus',
      'getEditQuestionStatus',
      'getDeleteQuestionStatus',
      'getAddQuestionnaireStatus',
      'getEditQuestionnaireStatus',
      'getDeleteQuestionnaireStatus'
    ]),
    ...mapGetters('auth', ['getJWTToken']),
    headers() {
      return this.calculateHeaders(this.tableData)
    },
    question() {
      return this.selectedItem
    },
    questionnaire() {
      return this.selectedItem
    },
    title() {
      if (this.mode === 'Questions')
        return 'Administrare Intrebari'

      if (this.mode === 'Questionnaires')
        return 'Administrare Chestionare'

      return ''
    },
    addButtonText() {

      if (this.mode === 'Questions')
        return 'Intrebare Noua'

      if (this.mode === 'Questionnaires')
        return 'Chestionar Nou'

      return ''
    },
    footerItemsPerPageText() {
      if (this.mode === 'Questions')
        return 'Intrebari pe pagina: '

      if (this.mode === 'Questionnaires')
        return 'Chestionare pe pagina: '

      return ''
    }
  },
  watch: {
    //Check the update status
    getAddQuestionStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //close the dialog if the action was completed successfully
        this.dialog = false
      }
      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});

      //At the end enable the edit
      this.$refs.questionEdit.disableWaitResponse();
    },
    getEditQuestionStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //close the dialog if the action was completed successfully
        this.dialog = false
      }

      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});

      //At the end enable the edit
      this.$refs.questionEdit.disableWaitResponse();
    },
    getDeleteQuestionStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //delete the item from the array
        const index = this.tableData.map(e => e.id).indexOf(newValue.id);
        this.tableData.splice(index, 1)
        //close the dialog if the action was completed successfully
        this.dialog = false
      }

      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});
    },
    getAddQuestionnaireStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //close the dialog if the action was completed successfully
        this.dialog = false
      }
      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});
      //At the end enable the edit

      this.$refs.questionnaireEdit.disableWaitResponse();
    },
    getEditQuestionnaireStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //close the dialog if the action was completed successfully
        this.dialog = false
      }

      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});

      //At the end enable the edit
      this.$refs.questionnaireEdit.disableWaitResponse();
    },
    getDeleteQuestionnaireStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"});
        //delete the item from the array
        const index = this.tableData.map(e => e.id).indexOf(newValue.id);
        this.tableData.splice(index, 1)
        //close the dialog if the action was completed successfully
        this.dialog = false
      }

      if (newValue.status === 'fail')
        this.$toast.error(newValue.message, '', {position: "topCenter"});
    }
  },
  mounted() {
  }
}
</script>
