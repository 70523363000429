<template>
  <div>
    <v-row>
      <!--  Contact From    -->
      <v-col cols="12" md="6">
        <ReportIssue form-title="Contact" button-text="Trimite Mesajul"></ReportIssue>
      </v-col>


      <!--  Contact data like email and phone   -->
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="justify-center">
            Date Contact
          </v-card-title>

          <v-divider class="my-2"></v-divider>

          <v-card-text>
            <v-row justify="center" class="my-2">
              <span class="text-center title">HEREA D. ION INTREPRINDERE INDIVIDUALA</span>
            </v-row>
            <v-row justify="center" class="my-2">
              <v-col cols="12" md="9">
                <!--              <span class="text-center subtitle-1 font-weight-bold"> <v-icon size="25" color="primary">mdi-email</v-icon> E-MAIL:  </span><span class="text-center subtitle-1"> &nbsp; </span>-->
                <v-textarea prepend-icon="mdi-email" label="EMAIL" value="testeautoexplicate@gmail.com" readonly
                            rows="1" flat auto-grow></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center" class="my-2">
              <v-col cols="12" md="9">
                <!--              <span class="text-center subtitle-1 font-weight-bold"> <v-icon size="25" color="primary">mdi-email</v-icon> E-MAIL:  </span><span class="text-center subtitle-1"> &nbsp; </span>-->
                <v-textarea prepend-icon="mdi-phone" label="Telefon" value="0728 954 121" readonly rows="1" flat
                            auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </div>
</template>


<script>

import ReportIssue from "../components/ReportIssue";

export default {
  name: 'TermsAndConditions',
  components: {ReportIssue},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  }
}
</script>
