import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default {
    // eslint-disable-next-line no-unused-vars
    async getQuestions({commit, rootGetters}) {
        await axios.get('/questions', {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_QUESTIONS', response.data)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_QUESTIONS', null)
            })
    },
    async addQuestion({commit, rootGetters}, questionToAdd) {
        //CRATE THE FORM DATA AND ADD THE QUESTION AND IMAGE (QUESTION IS MANDATORY)
        const data = new FormData();

        data.append('question', new Blob([JSON.stringify(questionToAdd.question)], {
            type: "application/json"
        }));

        if (questionToAdd.image)
            data.append('image', questionToAdd.image)

        await axios.post('/questions', data, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Intrebare adaugata cu succes!'

            if (res.data.success)
                commit('SET_ADD_QUESTION_STATUS', {status: 'success', message: message})
            else
                commit('SET_ADD_QUESTION_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 400)
                    message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'

            }
            commit('SET_ADD_QUESTION_STATUS', {status: 'fail', message: message})
        })

    },
    async editQuestion({commit, rootGetters}, questionToAdd) {
        //CRATE THE FORM DATA AND EDIT THE QUESTION AND IMAGE (QUESTION IS MANDATORY)
        const data = new FormData();

        data.append('question', new Blob([JSON.stringify(questionToAdd.question)], {
            type: "application/json"
        }));

        if (questionToAdd.image)
            data.append('image', questionToAdd.image)

        await axios.put('/questions', data, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Intrebare Modificata cu succes!'

            if (res.data.success)
                commit('SET_EDIT_QUESTION_STATUS', {status: 'success', message: message})
            else
                commit('SET_EDIT_QUESTION_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 400)
                    message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'

            }
            commit('SET_EDIT_QUESTION_STATUS', {status: 'fail', message: message})
        })
    },
    async deleteQuestion({commit, rootGetters}, questionId) {
        await axios.delete('/questions/' + questionId, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Intrebarea cu ID: ' + questionId + ' stearsa cu succes!'
            if (res.data.success)
                commit('SET_DELETE_QUESTION_STATUS', {status: 'success', message: message, id: questionId})
            else
                commit('SET_EDIT_QUESTION_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 404)
                    message = 'Intrebarea pe care vrei sa o stergi nu exista pe serverul de Administrare! Reincarca pagina si incearca din nou! ' +
                        '\n Daca intrebarea ta contine comentarii, sterge prima oara comentariile.'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Daca aceasta intrebare este continuta in chestionare, aceasta nu poate fi stearsa! \n Daca nu este cazul, incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'
            }
            commit('SET_DELETE_QUESTION_STATUS', {status: 'fail', message: message})
        })
    },
    async addQuestionnaire({commit, rootGetters}, questionnaire) {
        await axios.post('/questionnaires', questionnaire, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(res => {
                let message = 'Chestionar adaugat cu succes!'

                if (res.data.success)
                    commit('SET_ADD_QUESTIONNAIRE_STATUS', {status: 'success', message: message})
                else
                    commit('SET_ADD_QUESTIONNAIRE_STATUS', {status: 'fail', message: res.data.message})
                // eslint-disable-next-line no-unused-vars
            }).catch((err) => {
                let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
                if (err.response) {
                    if (err.response.status === 400)
                        message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                    else if (err.response.status >= 500 && err.response.status < 600)
                        message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                    else
                        message = 'Te rugam incearca din nou mai tarziu!'

                }
                commit('SET_ADD_QUESTIONNAIRE_STATUS', {status: 'fail', message: message})
            })
    },
    async editQuestionnaire({commit, rootGetters}, questionnaireToAdd) {

        await axios.put('/questionnaires', questionnaireToAdd, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Chestionar Modificat cu succes!'

            if (res.data.success)
                commit('SET_EDIT_QUESTIONNAIRE_STATUS', {status: 'success', message: message})
            else
                commit('SET_EDIT_QUESTIONNAIRE_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 400)
                    message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'

            }
            commit('SET_EDIT_QUESTIONNAIRE_STATUS', {status: 'fail', message: message})
        })
    },
    async deleteQuestionFromQuestionnaire({commit, rootGetters}, {questionnaire, question}) {
        await axios.delete('/questionnaires/' + questionnaire.id + "/" + question.id, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Intrebarea cu ID: ' + question.id + ' a fost stearsa din Chestionarul cu ID: ' + questionnaire.id

            if (res.data.success)
                commit('SET_DELETE_QUESTION_FROM_QUESTIONNAIRE_STATUS', {status: 'success', message: message})
            else
                commit('SET_DELETE_QUESTION_FROM_QUESTIONNAIRE_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 400)
                    message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'

            }
            commit('SET_DELETE_QUESTION_FROM_QUESTIONNAIRE_STATUS', {status: 'fail', message: message})
        })
    },
    async addQuestionIntoQuestionnaire({commit, rootGetters}, {questionnaire, question}) {
        await axios.post('/questionnaires/' + questionnaire.id + "/" + question.id, {}, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Intrebarea cu ID: ' + question.id + ' a fost adaugata in Chestionarul cu ID: ' + questionnaire.id

            if (res.data.success)
                commit('SET_ADD_QUESTION_INTO_QUESTIONNAIRE_STATUS', {status: 'success', message: message})
            else
                commit('SET_ADD_QUESTION_INTO_QUESTIONNAIRE_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 400)
                    message = 'Datele introduse nu sunt valide! Te rugam sa mai verifici o data datele!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'

            }
            commit('SET_ADD_QUESTION_INTO_QUESTIONNAIRE_STATUS', {status: 'fail', message: message})
        })
    },
    async deleteQuestionnaire({commit, rootGetters}, questionnaireId) {
        await axios.delete('/questionnaires/' + questionnaireId, {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        }).then((res) => {
            let message = 'Chestionarul cu ID: ' + questionnaireId + ' sters cu succes!'
            if (res.data.success)
                commit('SET_DELETE_QUESTIONNAIRE_STATUS', {status: 'success', message: message, id: questionnaireId})
            else
                commit('SET_DELETE_QUESTIONNAIRE_STATUS', {status: 'fail', message: res.data.message})
        }).catch((err) => {
            let message = 'Serverul de Administrare intampina probleme la acest moment. Incearca din nou mai tarziu!'
            if (err.response) {
                if (err.response.status === 404)
                    message = 'Chestionarul pe care vrei sa il stergi nu exista pe serverul de Administrare! Reincarca pagina si incearca din nou!'
                else if (err.response.status >= 500 && err.response.status < 600)
                    message = 'Serverul de Administrare intampina probleme la acest moment. \n Incearca din nou mai tarziu!'
                else
                    message = 'Te rugam incearca din nou mai tarziu!'
            }
            commit('SET_DELETE_QUESTIONNAIRE_STATUS', {status: 'fail', message: message})
        })
    }

}
