import router from "../../router";

export default {
    SET_USER_DATA: (state, response) => {
        state['userData'] = response
    },
    SET_USER_JWT_TOKEN: (state, token) => {
        state['JWTToken'] = token
    },

    SET_LOGIN_RESPONSE: (state, loginResponse) => {
        if (loginResponse.token) {
            window.$cookies.set("auth_token", loginResponse.token)
            //activate the user login
            state['logInStatus'] = true;
        }

        loginResponse.token = null
        state['loginResponse'] = loginResponse
    },
    SET_SIGN_UP_RESPONSE: (state, signUpResponse) => {
        state['signUpResponse'] = signUpResponse
    },
    SET_USER_LOG_IN_STATUS: (state, status) => {
        state['logInStatus'] = status;
    },
    SIGN_OUT_USER: (state) => {
        window.$cookies.remove('auth_token')
        window.$cookies.remove('learning_progress')
        state['logInStatus'] = false
        state['loginResponse'] = {}
        state['signUpResponse'] = {}
        state['userData'] = {}
        state['JWTToken'] = null
        // eslint-disable-next-line no-unused-vars
        router.push('/').catch(err => {
        });
    },
    SET_CHANGE_PASSWORD_STATUS: (state, status) => {
        state['changePasswordStatus'] = status
    },
    SET_PASSWORD_RECOVERY_STATUS: (state, status) => {
        state['passwordRecoveryStatus'] = status
    },
    SET_ACTIVATE_EMAIL_REQUEST_STATUS: (state, status) => {
        state['activateEmailRequestStatus'] = status
    },
    SET_EMAIL_VERIFY_STATUS: (state, status) => {
        state['emailVerifyStatus'] = status
    }

}
