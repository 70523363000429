<template>
  <v-card shaped color="">
    <div>

      <v-card-text>

        <v-row v-if="!askQuestionsPressed" class="text-center my-2" justify="center">

          <!--  ASK BUTTONS + INFO -->
          <div v-if="!askForPremium">
            <v-col cols="12">
              <span class="font-weight-light subtitle-1">Ai nelamuriri despre aceasta intrebare? Intreaba un profesor de legislatie rutiera apasand butonul de mai jos!</span>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-btn @click="!canUserAsk ? askForPremium = true: askQuestionsPressed = true" color="primary" large
                     rounded>
                <v-icon left color="white">fa-question</v-icon>
                <span class="white--text"> Pune o întrebare </span>
              </v-btn>

            </v-col>
          </div>

          <!-- In case that the user is not premium or admin ask him to buy premium -->
          <v-expand-transition>
            <v-row v-show="askForPremium">
              <v-col cols="12">
            <span v-if="!canUserAsk" class="font-weight-bold subtitle-1">Trebuie să ai <span
                class="sea_orange--text"> Premium </span> pentru a pune intrebări profesorilor de legislatie ruteiera. </span>
              </v-col>
              <v-col cols="12">
                <v-btn rounded color="sea_orange" to="/premium">Alege Premium</v-btn>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-row>

      </v-card-text>

      <!--      The actual asking fields   -->
      <v-expand-transition>
        <v-card-text v-show="askQuestionsPressed">

          <!--   The field where the question is typed   -->
          <v-row class="text-center" justify="center">
            <v-col cols="11">
              <div v-if="!responseReceived">
                <v-form :disabled="responseReceived || questionSent">
                  <v-textarea v-model="question" label="Întrebarea ta" outlined :rules="questionRules"
                              counter></v-textarea>
                </v-form>


                <!-- ASK BUTTON  -->
                <v-card-actions>
                  <v-row>
                    <v-col cols="12" class="text-right">
                      <v-btn rounded color="primary" large :disabled="submitDisabled" @click="askQuestion"
                             :loading="sendButtonIsLoading">
                        Întreabă
                        <v-icon right size="30">mdi-head-question left</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </div>
              <!-- Response messages -->
              <div v-else>
                <v-row justify="center">
                  <v-col cols="10">
                <span
                    :class="`${this.getCommentAskedStatus.status === 'success' ? 'success' : 'error'}--text title font-weight-bold`">
                  {{ this.getCommentAskedStatus.message }}
                </span>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="this.getCommentAskedStatus.status === 'success'">
                  <v-col cols="10">
                <span class="subtitle-1 font-weight-bold"
                      v-if="this.getCommentAskedStatus.status === 'success'">
                  Un profesor de legislatie rutiera iti va răspunde la intrebare in cel mai scurt timp.
                  Verifică sectiunea "Intrebările Mele" pentru a vedea răspunsurile date de către profesor.</span>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-expand-transition>
    </div>
  </v-card>

</template>


<script>

import {mapActions, mapGetters} from "vuex";
import {hasAuthority} from "../../utilities/AuthUtilities";

export default {
  name: 'AskQuestion',
  props: {
    questionId: {
      type: Number,
      required: true
    }
  },
  components: {},
  data() {
    return {
      askQuestionsPressed: false,
      question: '',
      askForPremium: false,
      submitDisabled: true,
      sendButtonIsLoading: false,
      commentAskedSuccessfully: false,
      questionSent: false,
      responseReceived: false,
      questionRules: [
        (question) => (question.length >= 15) || 'Intrebarea trebuie sa aiba minimum 15 caractere.',
        (question) => (question.length < 250) || 'Intrebarea trebuie sa aiba maximum 250 de caractere.',
      ]
    }
  },
  methods: {
    hasAuthority,
    ...mapActions('app', ['askComment']),
    resetQuestion() {
      this.askQuestionsPressed = false
      this.question = ''
      this.askForPremium = false
      this.sendButtonIsLoading = false
      this.questionSent = false
      this.responseReceived = false
    },
    askQuestion() {
      this.questionSent = true
      this.sendButtonIsLoading = true
      this.askComment({askedQuestion: this.question, question: {id: this.questionId}})
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData']),
    ...mapGetters('app', ['getCommentAskedStatus']),
    canUserAsk() {
      return !hasAuthority( 'PAID_USER', this.getUserData.maxAuthorizationType)
    }
  },
  watch: {
    questionId() {
      //if question id changes reset the question
      this.resetQuestion()
    },
    question(newValue) {
      if (newValue.length >= 15 && newValue.length < 250 && this.questionId >= 0)
        this.submitDisabled = false
      else
        this.submitDisabled = true
    },
    getCommentAskedStatus(newValue) {
      if (newValue.status === 'success') {
        this.$toast.success(newValue.message, '', {position: "topCenter"})
        this.commentAskedSuccessfully = true
      } else if (newValue.status === 'fail') {
        this.$toast.error(newValue.message, '', {position: "topCenter"})
        this.commentAskedSuccessfully = false
      }

      this.responseReceived = true
      this.sendButtonIsLoading = false
    }
  },
  mounted() {
  }
}
</script>

<style lang="sass" scoped>


</style>
