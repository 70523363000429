import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard";
import MyQuestions from "@/views/MyQuestions";
import Chestionare from "@/views/Chestionare";
import SignUp from "../views/SignUp";
import Profil from "../views/Profil";
import AdminPanel from "../views/AdminPanel";
import PageNotFound from "../views/PageNotFound";
import store from "@/store"
import Chestionar from "../views/Chestionar";
import Legislation from "../views/Legislation";
import Premium from "@/views/Premium";
import PasswordChange from "../views/PasswordChange";
import VerifyEmail from "../views/VerifyEmail";
//import LearningMode from "../views/LearningMode";
import PaymentStatus from "../views/PaymentStatus"
import Books from "../views/Books";
import Vue from "vue";
import Contact from "../views/Contact";
import TermsAndConditions from "../views/TermsAndConditions";

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'dashboard',
        meta: {
            title: 'Teste Auto Explicate',
            description: 'Teste auto explicate, chestionare auto explicate si codul rutier explicat pentru categoria B. Invata rapid si eficient pentru a lua permisul de conducere din prima incercare pe calculatorul sau telefonul tau.'
        },
        component: Dashboard
    },
    {
        path: '/legislatie',
        name: 'legislatie',
        meta: {
            title: 'Codul Rutier Explicat - Teste Auto Explicate',
            description: 'Codul Rutier într-o formă concisă și ușor de înțeles.' +
                'Conține doar informațiile necesare pentru examen. Este scurt, ușor de citit și de înțeles.'
        },
        component: Legislation
    },
    {
        path: '/chestionare/:category?',
        name: 'chestionare',
        component: Chestionare
    },
    {
        path: '/chestionar/:id',
        name: 'pornesteChestionar',
        meta: {
            title: 'Rezolvare Chestionar Explicat - Teste Auto Explicate',
            description: 'Rezolvare Chestionare Explicate'
        },
        component: Chestionar
    },
    {
        path: '/intreaba',
        name: 'întrebări',
        meta: {
            title: 'Intrebarile Mele - Teste Auto Explicate',
            description: 'Pune intrebari profesorilor nostrii de legislatie rutiera. Acestia iti vor raspunde in cel mai scurt timp'
        },
        component: MyQuestions
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        meta: {
            title: 'Inregistrare - Teste Auto Explicate',
            description: 'Inregistreaza-te pe Teste Auto Explicate pentru a putea accesa continutul platformei.'
        },
        component: SignUp
    },
    {
        path: '/profil',
        name: 'profil',
        meta: {
            title: 'Profilul Meu - Teste Auto Explicate',
            description: 'Profilul Meu contine informatii despre contul tau.'
        },
        component: Profil
    },
    // {
    //     path: '/invatare',
    //     name: 'mod învațare',
    //     component: LearningMode
    // },
    {
        path: '/admin/panel',
        name: 'admin-panel',
        meta: {
            title: 'Admin Panel - Teste Auto Explicate',
            description: 'Administreaza Continutul Teste Auto Explicate'
        },
        component: AdminPanel
    },
    {
        name: 'premium',
        path: '/premium',
        meta: {
            title: 'Premium - Teste Auto Explicate',
            description: 'Abonamentul premium iti ofera acces nelimitat la resursele explicate ale platformei Teste Auto Explicate.'
        },
        component: Premium
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            title: 'Contact - Teste Auto Explicate',
            description: 'Contacteaza-ne daca intampini probleme in utlilizarea platformei.'
        },
        component: Contact
    },
    {
        path: '/termene',
        name: 'terms',
        meta: {
            title: 'Termene si Conditii - Teste Auto Explicate',
            description: 'Termene si Conditii aplicate pe Teste Auto Explicate.'
        },
        component: TermsAndConditions
    },
    {
        name: "PaymentSuccess",
        path: '/premium/:status',
        meta: {
            title: 'Confirmare Plata - Teste Auto Explicate',
            description: 'Vizualizeaza statusul platii abonamentului ales.'
        },
        component: PaymentStatus
    },
    {
        path: '/cont/parola/resetare/:code',
        name: 'resetPass',
        meta: {
            title: 'Resetare Parola - Teste Auto Explicate',
            description: 'Reseteaza-ti parola pe platforma.'
        },
        component: PasswordChange
    },
    {
        path: '/cont/parola/schimba',
        name: 'changePass',
        meta: {
            title: 'Schimbare Parola - Teste Auto Explicate',
            description: 'Schimba-ti parola pe platforma'
        },
        component: PasswordChange
    },
    {
        path: '/cont/parola/recuperare',
        name: 'recoveryPass',
        meta: {
            title: 'Recuperare Parola - Teste Auto Explicate',
            description: 'Recuperare Parola pe platforma.'
        },
        component: PasswordChange
    },
    {
        path: '/cont/verificare/:code',
        name: 'verifyEmail',
        meta: {
            title: 'Verificare Email - Teste Auto Explicate',
            description: 'Verificare Email cu cod.'
        },
        component: VerifyEmail
    },
    {
        path: '/carti',
        name: 'carti',
        meta: {
            title: 'Carti TesteAutoExplicate - Teste Auto Explicate',
            description: 'Ești adeptul învățatului clasic? Teste Auto Explicate îți oferă continutul aplicatiei si în in format fizic.'
        },
        component: Books
    },

    {
        name: 'page-not-found',
        path: '/404',
        meta: {
            title: 'Pagina nu a putut fi gasita - Teste Auto Explicate',
            description: 'Pagina nu a putut fi gasita!'

        },
        component: PageNotFound
    },
    {
        path: '*',
        redirect: '404'
    }


]


const router = new VueRouter({
    mode: 'history',
    routes: routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y : 0,
            behavior: 'smooth',
        }

    }
})


router.beforeEach((to, from, next) => {
        try {
            if (to.meta.title)
                window.document.title = to.meta.title
            else
                window.document.title = 'Pagina Principala - Teste Auto Explicate'

            if (to.meta.description)
                document.getElementsByTagName('meta')
                    .namedItem('description')
                    .setAttribute('content', to.meta.description)
            // eslint-disable-next-line no-empty
        } catch (err) {

        }

        if (to.name === 'întrebări' && store.getters['app/isQuestionFeatureDisabled']) {
            next({name: 'page-not-found'})
        } else if ((to.name === 'profil' || to.name === 'chestionare' || to.name === 'întrebări' || to.name === 'mod învațare' || to.name === 'premium') && !store.getters['auth/isUserLoggedIn']) {
            // check if the user is logged in before accessing profile if it is not redirect to sign-up
            next({name: 'sign-up', query: {error: to.path}})
        } else if (to.name === 'admin-panel' && (!store.getters['auth/isUserLoggedIn'] || store.getters['auth/getUserData'].maxAuthorizationType !== 'ADMIN'))
            next({name: 'page-not-found'})
        else
            next()
    }
)


export default router
