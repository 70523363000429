<template>

  <v-slide-y-transition>
    <!--    Info about each test category   -->
    <v-container class="chestionare" fluid v-show="show">
      <v-row>
        <v-col cols="12">
          <v-card shaped>
            <v-card-title>
              <h1 class="title">
                {{ textData.title }}
              </h1>
            </v-card-title>
            <v-card-text class="subtitle-1"> {{ textData.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!--    Filter Button  -->
          <v-row class="mb-3 pt-2"
                 v-if="hasAuthority('PAID_USER',this.getUserData.maxAuthorizationType)">
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn small text color="black" @click="triggerOnlyAvailableQuestionnaires()" v-on="on">
                  <v-icon left>fa-filter</v-icon>
                  <span>Filtrare Chestionare</span>
                </v-btn>
              </template>
              <span v-if="onlyAvailableQuestionnaires">Afiseaza Toate Chestionarele</span>
              <span v-else>Afiseaza Doar Chestionarele Disponibile</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>


      <!--    Available Questionnaires   -->
      <v-row class="py-2" justify="space-around">
        <template v-for="(questionnaire,index) in filteredQuestionnaire" :id="index">
          <v-hover v-slot="{hover}" :key="index">
            <v-col cols="12" sm="6" md="3" :key="index" v-if="showFilteredQuestionnaire(questionnaire)">
              <QuestionnaireCard :questionnaire="questionnaire" :index="index + 1"
                                 :disabled="isQuestionnaireDisabled(questionnaire)"
                                 :buy-option="!(hover && isQuestionnaireDisabled(questionnaire))"
                                 :learning-mode="isInLearningMode"
                                 ref="questionnaireCard" :progress-id="parseInt(statsId)">
              </QuestionnaireCard>
            </v-col>
          </v-hover>
        </template>
      </v-row>
    </v-container>
  </v-slide-y-transition>
</template>

<script>


//import Questionnaire from "@/components/Questionnaire";
import QuestionnaireCard from "../components/Questionnaires/QuestionnaireCard";
import {mapActions, mapGetters} from "vuex";
import {hasAuthority} from "../utilities/AuthUtilities";

export default {
  name: 'Chestionare',
  components: {QuestionnaireCard},
  data() {
    return {
      onlyAvailableQuestionnaires: false,
      show: false,
      statsId: 0
    }
  },
  computed: {
    ...mapGetters('app', ['questionnaires', 'getQuestionnairesStatisticsScores']),
    ...mapGetters('auth', ['getLoginResponse', 'getSignUpResponse', 'getUserData', 'isUserLoggedIn']),
    isInLearningMode() {
      return this.$route.params.category && this.$route.params.category === 'invatare'
    },
    filteredQuestionnaire() {

      let currFilter = this.$route.params.category

      if (!currFilter)
        currFilter = 'B'

      currFilter = currFilter.toUpperCase()

      if (currFilter === 'INVATARE' && this.questionnaires)
        return this.questionnaires.filter(el => el.category === 'B')

      if (this.questionnaires)
        return this.questionnaires.filter(el => el.category === currFilter)
      else
        return []


    },
    textData() {

      let currFilter = this.$route.params.category

      if (!currFilter)
        currFilter = 'B'

      currFilter = currFilter.toUpperCase()

      try {
        window.document.title = 'Chestionare Auto Explicate Categoria ' + currFilter
        // eslint-disable-next-line no-empty
      } catch (err) {

      }

      if (currFilter === 'B')
        return {
          title: "Teste Auto Categoria B", description: "Aici lucrați exact ca la examen.\n" +
              "Cu cronometru și cu toate butoanele.\n" +
              "\n" +
              "După fiecare test puteți vedea unde ați greșit.\n" +
              "Aflați răspunsul corect și explicația care vă\n" +
              "lămurește de ce ați greșit."
        }
      else if (currFilter === 'REDOBANDIRE')
        return {
          title: "Teste Auto Redobândire", description: "Aici găsiți întrebările și răspunsurile\n" +
              "corecte, pentru redobândirea mai\n" +
              "rapidă a permisului, reducând reținerea\n" +
              "pentru 60 ori 90 de zile, la 30 de zile."
        }
      else if (currFilter === 'INVATARE')
        return {
          title: "Învățare rapidă",
          description: "Înainte să vă apucați de rezolvat Teste, este bine să parcurgeți acest Capitol. " +
              "Sunt aceleași întrebări ca cele din Teste, dar aveți avantajul că după fiecare întrebare puteți afla răspunsul corect. " +
              "În plus, puteți citi explicația, de ce un răspuns este bun," +
              " dar și de ce un răspuns greșit nu este bun. Aceste explicații vă ajută să rețineți mult mai ușor răspunsurile corecte."
        }

      else return {
          title: "", description: ""
        }


    }
  },
  methods: {
    hasAuthority,
    ...mapActions('app', ['getQuestionnaires', 'getStatistics']),
    isQuestionnaireDisabled(questionnaire) {
      return hasAuthority(questionnaire.visibility, this.getUserData.maxAuthorizationType)
    },
    triggerOnlyAvailableQuestionnaires() {
      this.onlyAvailableQuestionnaires = !this.onlyAvailableQuestionnaires
    },
    showFilteredQuestionnaire(questionnaire) {
      if (this.isQuestionnaireDisabled(questionnaire))
        if (this.onlyAvailableQuestionnaires)
          return false
      return true
    },
    mountComponent() {
      this.show = true
      //load the questionnaires only if they were not already loaded
      if (!this.questionnaires || Object.keys(this.questionnaires).length === 0) {
        //await because we first need all the questionnaires then we retrieve the progress
        this.getQuestionnaires().then(() => {
          this.getStatistics()
        })
      } else this.getStatistics()

      if (this.questionnaires)
        this.questionnaires.forEach(questionnaire => questionnaire.progress = null)
    }
  },
  mounted() {
    this.mountComponent()
  },
  watch: {
    getQuestionnairesStatisticsScores(newValue) {
      if (newValue && this.questionnaires) {

        let questionnaires = this.questionnaires.map(questionnaire => questionnaire.id)

        try {
          newValue.forEach((stats) => this.questionnaires[questionnaires.indexOf(stats.questionnaireId)].progress = stats.maxScore)
          // eslint-disable-next-line no-empty
        } finally {
        }
        //force update the statistics
        this.statsId++
      }
    },
    questionnaires() {
      //load the questionnaires in case that they are not loaded (e.g. mounted before login)
      if (this.isUserLoggedIn) {
        this.mountComponent()
      }
    }
  }
}
</script>
