import Vuex from "vuex";
import Vue from 'vue'
import auth from './auth/auth'
import admin from './admin/admin'
import app from './app/app'
import premium from './premium/premium'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: auth,
        admin: admin,
        app: app,
        premium: premium
    }
})
