import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    theme: {
        themes: {
            light: {
                primary: '#0C4A60',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                sea_orange: '#EF6C33',
                blue_eyes: '#ABDFF1',
                sea_gray: '#E1DDDB',
                sea_dark_blue: '#25274D',
                sea_light_blue: '#ABDFF1',
                iris: '#9867C5',
                emerald: '#028A0F'

            }
        }
    }
});
