<template>
  <div>
    <v-card color="accent darken-1" class="text-center" height="100%" width="100%" elevation="6"
            :disabled="disabled && buyOption"
            outlined>

      <!-- Title and Questionnaire Type -->
      <v-card-title class="text-wrap">
        <div v-if="showTitle">{{ questionnaire.title }}</div>
        <div v-else><span v-if="learningMode">Test Rapid</span>
          <span v-else>Chestionar</span>
          {{ index }}
        </div>
        <v-spacer></v-spacer>
        <v-chip :class="`${questionnaire.visibility} white--text`">{{
            translate(questionnaire.visibility)
          }}
        </v-chip>
      </v-card-title>

      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        <v-tooltip bottom nudge-top="26">
          <template v-slot:activator="{ on, attrs }">
            <v-row justify="space-around" v-on="on" v-bind="attrs">
              <v-col cols="2">
                <v-icon>mdi-chart-line</v-icon>
              </v-col>
              <v-col cols="10">
                <!-- Progress Bar -->

                <v-progress-linear :value="questionnaire.progress/questionnaire.numberOfQuestions * 100 "
                                   striped color="emerald" height="20" rounded :id="progressId">
                  <div v-if="isUserLoggedIn" class="font-weight-bold">
                    {{ questionnaire.progress ? questionnaire.progress : 0 }}
                    {{ '/' + questionnaire.numberOfQuestions }}
                  </div>
                  <div class="font-weight-bold text-no-wrap" v-else>0/0</div>
                </v-progress-linear>
              </v-col>
            </v-row>
          </template>
          <span>Progresul tau in acest chestionar</span>
        </v-tooltip>
      </v-card-text>

      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip color="sea_dark_blue lighten-5" label class="elevation-3" v-on="on" v-bind="attrs">
              <strong>{{ questionnaire.category }}</strong></v-chip>
          </template>
          <span>Categoria Chestionarului Selectat</span>
        </v-tooltip>
        <v-row class="ma-1" justify="end">
          <v-btn rounded color="black"
                 @click="solveQuestionnairePressed()">
            <v-icon left color="white">fa-pencil-square</v-icon>
            <span class="white--text" v-if="!isUserLoggedIn && !buyOption">Logheaza-te mai întai</span>
            <span class="white--text" v-else-if="!buyOption">Cumpără Abonament</span>
            <span v-else class="white--text">Rezolvă</span></v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {hasAuthority} from "../../utilities/AuthUtilities";

export default {
  name: 'QuestionnaireCard',
  props: {
    questionnaire: {
      type: Object,
      default: () => {
        return {
          title: "Title",
          progress: 20,
          numberOfQuestions: 26,
        }
      }
    },
    learningMode: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buyOption: {
      type: Boolean,
      default: false
    },
    progressId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn', 'getUserData'])
  },
  methods: {
    hasAuthority,
    translate(text) {
      switch (text) {
        case 'ADMIN':
          return 'Administrator'
        case 'FREE_USER':
          return 'Gratuit'
        case 'PAID_USER':
          return 'Premium'
      }
    },
    vCardClick() {
      if (!this.buyOption)
        this.$router.push('/premium')
    },
    solveQuestionnairePressed() {
      if (!hasAuthority(this.questionnaire.visibility, this.getUserData.maxAuthorizationType)) {

        this.$router.push({
          path: '/chestionar/' + this.questionnaire.id,
          query: {invatare: this.learningMode, root: this.$route.path}
        })

        // eslint-disable-next-line no-empty
      } else {
        this.$router.push('/premium')
        this.$toast.info("Acest test este disponibil doar celor abonați!", 'Premium', {position: "topCenter"})
      }

    }
  },
  watch: {}
}
</script>

<style scoped lang="sass">

.v-card
  border-radius: 25px !important

</style>
