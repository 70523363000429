<template>
  <v-card :loading="this.loading" :elevation="this.elevation" class="mx-auto text-center" max-width="600" rounded>
    <v-avatar class="mx-auto my-2" size="120">
      <v-img :src="getUserImageSource" height="100%" width="100%"></v-img>
    </v-avatar>
    <v-card-title class="justify-center">
      {{ this.name }}
    </v-card-title>

    <v-divider class="mx-4"></v-divider>

    <v-card-text>

      <div class="font-weight-bold darken-4">
        Tip user:
      </div>
      <v-chip small :class="`${this.role} white--text caption my-2`">
        {{ getUserRole }}
      </v-chip>

      <v-divider class="mx-4"></v-divider>

    </v-card-text>
<!--    <v-btn v-if="this.role !== 'ADMIN'" class="mb-3" rounded dark color="red" @click="$router.push('/premium')"> {{ getUpgradeMessage }}</v-btn>-->
  </v-card>
</template>

<script>


export default {
  name: 'UserProfile',
  props: {
    name: String,
    profilePicture: String,
    role: String,
    elevation: Number,
    loading: Boolean
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    getUserRole() {
      if (this.role === 'ADMIN')
        return 'Administrator'
      if (this.role === 'FREE_USER')
        return 'Utilizator Gratuit'
      if (this.role === 'PAID_USER')
        return 'Utilizator Premium'

      return 'Fara Rol'
    },
    getUserImageSource() {
      if (this.profilePicture) {
        return this.profilePicture
      } else {
        return require('@/avatars/avatar1.svg')
      }
    },
    getUpgradeMessage() {
      if (this.role === 'FREE_USER')
        return 'Alege Premium'

      if (this.role === 'PAID_USER')
        return 'Prelungire Abonament'

      return ''
    }
  }
}
</script>

<style lang="sass">
//NON SCOPED STYLE FOR CHIPS

.v-chip.ADMIN
  background: #c22a34 !important


.v-chip.FREE_USER
  background: #26de81 !important


.v-chip.PAID_USER
  background: #FFD700 !important

</style>
