import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default {
    async getQuestionnaires({commit, rootGetters}) {
        await axios.get('/questionnaires', {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_QUESTIONNAIRES', response.data)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_QUESTIONNAIRES', null)
            })
    },
    async getQuestionnaireById({commit, rootGetters}, id) {
        await axios.get('/questionnaires/' + id, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_LOADED_QUESTIONNAIRE_DATA', response.data)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_LOADED_QUESTIONNAIRE_DATA', null)
            })
    },
    resetLoadedQuestionnaire({commit}) {
        commit('SET_LOADED_QUESTIONNAIRE_DATA', null)
    },
    async getLegislationChapters({commit, rootGetters}) {
        await axios.get('/legislation', {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_LEGISLATION_CHAPTERS', response.data.payload)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_LEGISLATION_CHAPTERS', null)
            })

    },
    async getLegislationChapter({commit, rootGetters}, chapterInfo) {
        await axios.get('/legislation/' + chapterInfo.id + '/' + chapterInfo.subchapter, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_LEGISLATION_CHAPTER', {chapterData: response.data.payload, chapterInfo: chapterInfo})
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_LEGISLATION_CHAPTER', null)
            })

    },
    async uploadStatistics({commit, rootGetters}, statistics) {
        await axios.post('/statistics', statistics, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_STATISTICS_UPLOAD_STATUS', response)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_STATISTICS_UPLOAD_STATUS', err)
            })
    },
    async getStatistics({commit, rootGetters}) {
        await axios.get('/statistics', {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_QUESTIONNAIRES_STATISTICS_SCORES', response.data.payload)
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_QUESTIONNAIRES_STATISTICS_SCORES', null)
            })
    },
    async askComment({commit, rootGetters}, question) {
        await axios.post('/comments', question, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_COMMENT_ASKED_STATUS', {
                    status: 'success',
                    message: 'Intrebarea a fost trimisă cu succes!'
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_COMMENT_ASKED_STATUS', {
                    status: 'fail',
                    message: 'Intrebarea nu a fost trimisă cu succes! Incearcă din nou mai târziu.'
                })
            })
    },
    async getComments({commit, rootGetters}, page) {
        await axios.get('/comments?page=' + page, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            .then(response => {
                commit('SET_COMMENTS', response.data)

                if (response.data.payload.length < 5)
                    commit('SET_COMMENTS', [])
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_COMMENTS', [])
            })

    },
    async deleteComments({commit}) {
        commit('DELETE_COMMENTS')
    },
    async deleteCommentsById({commit}, id) {
        commit('DELETE_COMMENTS_BY_ID', id)
    },
    async markQuestionAsAnswered({commit}, id) {
        commit('MARK_QUESTION_AS_ANSWERED', id)
    },
    async answerComment({commit, rootGetters}, answer) {
        await axios.post('/comments/answer', answer, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_COMMENT_ANSWER_STATUS', {
                    status: 'success',
                    message: 'Întreabarea a fost răspunsa cu succes!',
                    id: answer.commentId
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_COMMENT_ANSWER_STATUS', {
                    status: 'fail',
                    message: 'Întreabarea NU a fost răspunsa cu succes!',
                    id: answer.commentId
                })
            })
    },
    async deleteComment({commit, rootGetters}, commentId) {
        await axios.delete('/comments/' + commentId, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_DELETE_COMMENT_STATUS', {
                    status: 'success',
                    message: 'Întreabarea a fost ștearsa cu succes.',
                    id: commentId
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_DELETE_COMMENT_STATUS', {
                    status: 'fail',
                    message: 'Întreabarea NU a putut fi ștearsa.',
                    id: commentId
                })
            })
    },
    async submitIssue({commit, rootGetters}, issue) {
        await axios.post('/issues', issue, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })     // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_SUBMIT_ISSUE_STATUS', {
                    status: 'success',
                    message: 'Cererea ta a fost înregistrată. Îti vom raspunde la aceasta in cel mai scurt timp!',
                })
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_SUBMIT_ISSUE_STATUS', {
                    status: 'fail',
                    message: 'Cererea ta NU a putut fi fost înregistrată. Încearca din nou mai târziu!!',
                })
            })
    },
}
