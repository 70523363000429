import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

export default {
    async requestPaymentGeneratedLink({commit, rootGetters}, paymentOption) {
        await axios.get('/payments?payment_option=' + paymentOption, {
            headers: {
                'Authorization': `Bearer ` + rootGetters["auth/getJWTToken"]
            }
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                commit('SET_PAYMENT_GENERATED_LINK', {status: 'success', url: response.data.payload})
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                commit('SET_PAYMENT_GENERATED_LINK', {status: 'fail', url: null})
            })
    },
}
