<template>
  <div>
    <PasswordRecovery :mode="mode"></PasswordRecovery>
  </div>
</template>


<script>
import PasswordRecovery from "../components/Recovery/PasswordRecovery";

export default {
  name: 'PasswordChange',
  components: {PasswordRecovery},
  data() {
    return {}
  },
  computed: {
    mode() {
      let path = this.$route.fullPath
      if (path.includes('resetare'))
        return 'RESET'
      else if (path.includes('recuperare'))
        return 'RECOVERY'
      else if (path.includes('schimba'))
        return 'CHANGE'
      return ''

    }
  }
}
</script>
