<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="12" sm="8" md="4" v-for="cat in editCategories" :key="cat.id">
        <v-card
            class="mx-auto"
            color="sea_orange"
            max-width="400"
            dark
            rounded
            elevation="10"
            height="100%"
        >
          <v-card-title>
            <v-icon large left>
              {{ cat.icon }}
            </v-icon>
            <span class="text-h6 font-weight-light"> {{ cat.id }}</span>
          </v-card-title>

          <v-card-text class="text-h5 font-weight-bold">
            {{ cat.text }}
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-row align="center" justify="end">
                <v-btn rounded color="black" @click="$emit(cat.event)">
                  <v-icon left>fa-pencil-square</v-icon>
                  <span>Editare</span></v-btn>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: 'AdminQuestionEdit',
  components: {},
  data() {
    return {
      editCategories: [
        {
          id: 'Intrebari',
          buttonText: 'Adauga/Editeaza Intrebari',
          text: 'Apasa aici pentru a adauga/edita intrebari',
          icon: 'mdi-help-circle',
          event: 'question-edit'
        },
        {
          id: 'Chestionare',
          buttonText: 'Adauga/Editeaza Chestionare',
          text: 'Apasa aici pentru a adauga/edita chestionare',
          icon: 'mdi-file-document-edit',
          event: 'questionnaire-edit'
        },
        // {
        //   id: 'Utilizatori',
        //   buttonText: 'Adauga/Editeaza Utilizatorii',
        //   text: 'Apasa aici pentru a vizualiza utilizatorii',
        //   icon: 'mdi-account-supervisor',
        //   event: 'user-edit'
        // }
      ]
    }
  }
}
</script>
