<template>
  <v-card color="primary lighten-3">
    <!--    Title      -->
    <v-card-title class="justify-center font-weight-bold text-h5">
      Alege capitolul:
    </v-card-title>
    <!--  Chapter choosers  -->
    <v-list shaped color="primary lighten-3" rounded>
      <v-list-group
          v-for="chapter in chapters"
          :key="chapter.title"
          v-model="chapter.isActive"
          prepend-icon="fa-book"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="chapter.name"
                               :class="selectedChapter !== null && selectedChapter.chapter === chapter.id ? 'font-weight-bold' : ''"/>
          </v-list-item-content>
        </template>

        <!--        Sub Chapter Choosers    -->
        <v-list-item ripple link
                     v-for="(subChapter,index) in chapter.subChapters"
                     :key="subChapter.name"
                     @click="chapter.isActive = false; selectedChapter = {chapter: chapter.id,subchapter: subChapter.id}; $emit('new-chapter',{
                       id: chapter.id,
                       subchapter: index,
                       name: subChapter.name,
                       allowed: !hasAuthority(subChapter.minAuthorization,getUserData.maxAuthorizationType)})"
                     :class="selectedChapter !== null && selectedChapter.subchapter === subChapter.id && selectedChapter.chapter === chapter.id ? 'font-weight-bold' : ''">
          <v-list-item-content>
            <v-list-item-title
                :class="hasAuthority(subChapter.minAuthorization,getUserData.maxAuthorizationType) ? 'text--disabled' : 'black--text'">
              <v-icon left small class="mb-1">
                {{
                  !hasAuthority(subChapter.minAuthorization, getUserData.maxAuthorizationType) ? 'fa-circle' : 'fa-lock'
                }}
              </v-icon>
              {{ subChapter.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>


import {mapGetters} from "vuex";
import {hasAuthority} from '../../utilities/AuthUtilities';


export default {
  name: 'LegislationChapterChooser',
  components: {},
  props: {
    chapters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedChapter: null
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserData'])
  },
  methods: {
    hasAuthority
  },
  watch: {},
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="sass">


</style>
