export default {
    logInStatus: false,
    JWTToken: null,
    loginResponse: null,
    signUpResponse: null,
    userData: {},
    changePasswordStatus: null,
    passwordRecoveryStatus: null,
    activateEmailRequestStatus: null,
    emailVerifyStatus: null
}
