export default {
    questions: {loaded: false},
    questionsReceivedStatus: false,
    addQuestionStatus: null,
    editQuestionStatus: null,
    deleteQuestionStatus: null,
    deleteQuestionnaireStatus: null,
    addQuestionnaireStatus: null,
    editQuestionnaireStatus: null,
    deleteQuestionFromQuestionnaireStatus: null,
    addQuestionIntoQuestionnaireStatus: null,
}
