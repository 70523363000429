<template>
  <div>
    <v-row justify="space-around">
      <v-overlay :value="loading">
        <v-progress-circular
            indeterminate
            size="80"
            color="amber"
            :width="5"
        ></v-progress-circular>
      </v-overlay>

      <v-col cols="12" v-if="!finished && !loading">
        <Questionnaire
            :initialQuestionNo="initialQuestionNo"
            :remained-question-no="remainedQuestionNo"
            :correct-answers="correctAnswers"
            :wrong-answers="wrongAnswers"
            :timer="timer"
            :progress="progress"
            :question="this.questions[this.index]"
            ref="questionnaire"
            @send="sendQuestion"
            @skip="skipQuestion"
            @next="nextQuestion"
            :learning-with-questionnaire="learningMode"
            :lock-choose="disabledQuestionnaire"
            :visible-explanation="visibleExplanation"
        ></Questionnaire>

        <v-col cols="12">
          <AskQuestion :question-id="questions[this.index] ? questions[this.index].id : -1"
                       v-if="!isQuestionFeatureDisabled"/>
        </v-col>


      </v-col>


      <v-col cols="12" v-else-if="finished && !loading">
        <QuestionnaireSummary :questionnaire-id="id"
                              :initial-question-no="initialQuestionNo"
                              :min-passing="minPassing"
                              :wrong-answers="wrongAnswers"
                              :correct-answers="correctAnswers"
                              :wrong-questions="this.finalAnswers.filter((a) => !a.correctness)">
        </QuestionnaireSummary>
      </v-col>

    </v-row>

    <Advertisement :before-summary="true" v-if="displayAds"/>
  </div>


</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Questionnaire from "./Questionnaire";
import QuestionnaireSummary from "./QuestionnaireSummary";
import AskQuestion from "../Questions/AskQuestion";
import Advertisement from "../Advertisements/Advertisement";

export default {
  name: 'LaunchQuestionnaire',
  components: {Advertisement, QuestionnaireSummary, Questionnaire, AskQuestion},
  props: {
    id: {
      required: true,
      type: Number
    },
    learningMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      loadingTimeout: null,
      timerInterval: null,
      disabledQuestionnaire: false,
      visibleExplanation: false,
      initialQuestionNo: 0,
      remainedQuestionNo: 0,
      correctAnswers: 0,
      wrongAnswers: 0,
      minPassing: 0,
      maxTimeInSeconds: 0,
      finalAnswers: [],
      questions: [],
      index: 0,
      timer: '00:00',
      finished: false,
      displayAds: false
    }
  },
  computed: {
    ...mapGetters('app', ['getLoadedQuestionnaire', 'isQuestionFeatureDisabled']),
    progress() {
      if (this.getLoadedQuestionnaire)
        return this.maxTimeInSeconds * 100 / (this.getLoadedQuestionnaire.maxTimeInMinutes * 60)
      return 100
    }
  },
  methods: {
    ...mapActions('app', ['getQuestionnaireById', 'resetLoadedQuestionnaire', 'uploadStatistics']),
    initQuestionnaire() {

      //init questionnaire if it is not in wrong question mode
      this.displayAds = false
      this.loading = true
      //get the questionnaire
      this.getQuestionnaireById(this.id)
      //wait 15 seconds for the response, if the timeout is not canceled reroute to questionnaire chooser page
      this.loadingTimeout = setTimeout(() => {
        this.$router.push("/chestionare")
        this.$toast.error("Chestionarul nu a putut fi incarcat! Incearca din nou! " +
            "\n Daca problema persista contacteaza echipa de suport!", 'Eroare', {position: "topCenter"})
      }, 15000);
    },
    getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    },
    startTimer() {
      //if we are in learning mode assign a lot of time (2 hours)
      if (this.learningMode)
        this.maxTimeInSeconds = parseInt(2 * 60 * 60 * 60 / 60, 10)

      //start the questionnaire timer
      this.timerInterval = setInterval(() => {

        let minutes = parseInt(this.maxTimeInSeconds / 60, 10);
        let seconds = parseInt(this.maxTimeInSeconds % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer = minutes + ":" + seconds;

        if (--this.maxTimeInSeconds < 0) {
          this.timeoutReached()
          clearInterval(this.timerInterval)
        }
      }, 1000);
    },
    timeoutReached() {
      clearTimeout(this.loadingTimeout)
      clearInterval(this.timerInterval)
      this.finished = true
    },
    skipQuestion() {
      if (this.questions.length > 1) {
        //remove the question and push it at the end (of the queue)
        let question = this.questions[this.index]
        this.questions.splice(this.index, 1)
        this.questions.push(question)
      }
    },
    sendQuestion(correctness) {
      if (this.learningMode)
        this.answerLearningQuestion(correctness)

      if (correctness)
        this.correctAnswers++
      else
        this.wrongAnswers++

      this.remainedQuestionNo--

      let question = this.questions[this.index]

      //delete at this step only if we are not in the learning mode
      if (!this.learningMode)
        this.questions.splice(this.index, 1)

      let answers = this.$refs.questionnaire.packUpQuestion(true, true)

      this.finalAnswers.push({id: question.id, correctness: correctness, fullQuestion: answers})


    },
    async nextQuestion() {
      this.visibleExplanation = false
      this.disabledQuestionnaire = false

      this.$refs.questionnaire.performNavigationActionById("DELETE")
      this.questions.splice(this.index, 1)
      this.$refs.questionnaire.switchToNextButton()

    },
    async answerLearningQuestion(correctness) {
      if (correctness)
        this.$toast.success("Ai răspuns corect la aceasta întrebare!", "Corect", {position: "topCenter"})
      else
        this.$toast.error("Ai răspuns greșit la aceasta întrebare!", "Gresit", {position: "topCenter"})

      this.visibleExplanation = true
      this.$refs.questionnaire.wrongQuestionMark()

      this.disabledQuestionnaire = true

      if (this.questions && this.questions.length > 0) {
        this.$refs.questionnaire.switchToNextButton()
      }
    }
  },
  watch: {
    //watch if the questionnaire was loaded successfully
    getLoadedQuestionnaire(newValue) {
      if (newValue) {
        clearTimeout(this.loadingTimeout)
        this.loading = false
        //init questionnaire data
        this.initialQuestionNo = this.getLoadedQuestionnaire.questions.length
        this.minPassing = this.getLoadedQuestionnaire.minPassingNumber
        this.maxTimeInSeconds = this.getLoadedQuestionnaire.maxTimeInMinutes * 60
        this.remainedQuestionNo = this.initialQuestionNo
        this.correctAnswers = 0
        this.wrongAnswers = 0
        this.finalAnswers = []
        this.finished = false
        this.index = 0
        //sort the questions by id (remove later) - backend returns unordered sets not lists => more question diversity due to random order
        //TODO: REMOVE LATER
        this.questions = this.getLoadedQuestionnaire.questions.sort((a, b) => {
          return a.id - b.id
        })

        this.startTimer(this.timer)

      } else {
        this.$router.push("/chestionare")
      }
    },
    //if the id changes re-init the questionnaire
    id() {
      this.initQuestionnaire()
    },
    questions(newValue) {
      //if there are no more questions to answer finish the questionnaire
      if (newValue.length === 0) {
        this.finished = true
      }
    },
    wrongAnswers(newValue) {
      //check if the questionnaire can still be passed and it is not in learning mode. If not end it.
      if (!this.learningMode && newValue > this.initialQuestionNo - this.minPassing) {
        this.timeoutReached()
      }
    },
    finished(newValue) {
      //When the questionnaire is finished send the statistics to the backend
      if (newValue === true) {
        //DISPLAY ADS
        let randomNo = this.getRandomArbitrary(1, 200)

        if (randomNo >= 15 && randomNo <= 45)
          this.displayAds = true

        this.uploadStatistics({
          questionnaireId: this.id,
          score: this.correctAnswers,
          wrongQuestionList: this.finalAnswers.filter((a) => !a.correctness).map((a) => {
            return {id: a.id}
          })
        })
      }
    }
  },
  //when mounted init the questionnaire
  mounted() {
    this.initQuestionnaire()
  },
  beforeDestroy() {
    this.resetLoadedQuestionnaire()
    this.timeoutReached()
  }
}
</script>

<style scoped lang="sass">


</style>
