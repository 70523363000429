function getHierarchy(privilege) {
    switch (privilege) {
        case "FREE_USER":
            return 0;
        case "PAID_USER":
            return 1;
        case "ADMIN":
            return 999
        default:
            return -999

    }
}

function hasAuthority(mainPrivilege, toCompare) {
    return getHierarchy(toCompare) < getHierarchy(mainPrivilege)
}

export {
    hasAuthority
}
