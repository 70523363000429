<template>
  <div class="questionnaire-and-question">
    <v-row>
      <v-col cols="12">
        <QuestionDisplayAndAnswer :info-mode="true" :question="comment" @more-info="showDialog = true"></QuestionDisplayAndAnswer>
        <div>
          <!--    The dialog that contains the actual question  -->
          <v-dialog v-model="showDialog" transition="dialog-bottom-transition" width="100%">
            <v-card>

              <div class="px-3 py-3">
                <v-row class="py-2">
                  <v-card-title>Întrebarea din chestionar la care ai avut nelămuriri:</v-card-title>
                  <v-col cols="12">
                    <Questionnaire :question="comment.question" :wrong-question-mode="true"
                                   :ask-question-mode="true" class="elevation-7"></Questionnaire>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-title>Întrebarea ta și răspunsul profesorului de legislatie rutiera:</v-card-title>
                    <QuestionDisplayAndAnswer :question="comment"></QuestionDisplayAndAnswer>
                  </v-col>
                </v-row>


                <v-row justify="center">
                  <v-col cols="12" class="text-center">
                    <!-- BACK BUTTON (CLOSE DIALOG) -->
                    <v-btn color="primary" rounded @click="showDialog = false">
                      <v-icon left>fa-chevron-left</v-icon>
                      Intoarce-te la intrebari
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import QuestionDisplayAndAnswer from "./QuestionDisplayAndAnswer";
import Questionnaire from "../Questionnaires/Questionnaire";

export default {
  name: 'QuestionnaireAndQuestion',
  components: {QuestionDisplayAndAnswer, Questionnaire},
  props: {
    comment: {
      type: Object,
      required: true,
      default: () => {
        return {
          "id": 36,
          "question": {
            "id": 329,
            "question": "Precizați care vehicule sunt exceptate de la obligația acordării priorității de trecere:",
            "answerA": "cele care se pun în mișcare de pe loc față de cele care circulă",
            "answerB": "cele care ies din curți, ganguri sau garaje",
            "answerC": "autovehiculele din serviciile regulate de transport public, care se pun în mișcare din stația prevăzută cu alveolă",
            "explanation": "De obicei, vehiculele care se pun în mișcare trebuie să acorde prioritate celor care deja se mișcă. Excepție fac autovehiculele de transport public, care ies din stația prevăzută cu alveolă.",
            "title": "Precizati",
            "imageUrl": null,
            "category": "B",
            "c": true,
            "b": false,
            "a": false
          },
          "askedQuestion": "Intrebare",
          "active": false,
          "askDate": "2021-10-30T03:21:02.000+00:00"
        }
      }
    }
  },
  data() {
    return {
      showDialog: false
    }
  }
}
</script>
