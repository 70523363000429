<template>
  <div>
    <LaunchQuestionnaire :id="parseInt(this.$route.params.id)" :learning-mode="isInLearningMode"></LaunchQuestionnaire>
  </div>
</template>


<script>
import LaunchQuestionnaire from "../components/Questionnaires/LaunchQuestionnaire";

export default {
  name: 'Chestionar',
  props: {},
  components: {LaunchQuestionnaire},
  data() {
    return {}
  },
  computed: {
    id() {
      if (this.$route.params.id)
        return parseInt(this.$route.params.id)
      else return 0
    },
    isInLearningMode() {
      if (this.$route.query.invatare)
        return this.$route.query.invatare === 'true'
      else return false
    }
  },
  methods: {}
}
</script>
