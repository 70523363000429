export default {
    SET_QUESTIONNAIRES: (state, questionnaires) => {
        state.questionnaires = questionnaires
    },
    SET_LOADED_QUESTIONNAIRE_DATA: (state, questionnaire) => {
        state.loadedQuestionnaire = questionnaire
    },
    SET_LEGISLATION_CHAPTERS: (state, legislation) => {
        state.legislation.chapters = legislation
    },
    SET_LEGISLATION_CHAPTER: (state, {chapterData, chapterInfo}) => {
        if (!state.legislation.chaptersDataMap.has(chapterInfo.id)) {
            state.legislation.chaptersDataMap.set(chapterInfo.id, new Map())
        }

        state.legislation.chaptersDataMap.get(chapterInfo.id).set(chapterInfo.subchapter, chapterData)
    },
    SET_STATISTICS_UPLOAD_STATUS: (state, status) => {
        state.statisticsUploadStatus = status
    },
    SET_QUESTIONNAIRES_STATISTICS_SCORES: (state, statistics) => {
        state.questionnairesStatisticsScores = statistics
    },
    SET_COMMENT_ASKED_STATUS: (state, status) => {
        state.commentAskedStatus = status
    },
    SET_COMMENTS: (state, comments) => {
        if (comments.success === true) {
            //if there is no data on the pages, mark the comments as fully retrieved
            if (comments.payload.length === 0)
                state.fullRetrievedComments = true

            state.comments.push(...comments.payload)
        } else state.fullRetrievedComments = true
    },
    DELETE_COMMENTS: (state) => {
        state.comments = []
        state.fullRetrievedComments = false
    },
    DELETE_COMMENTS_BY_ID: (state, id) => {
        let deletePos = state.comments.map((comment) => comment.id).indexOf(id)
        if (deletePos !== -1)
            state.comments.splice(deletePos, 1)
    },
    MARK_QUESTION_AS_ANSWERED: (state, id) => {
        let deletePos = state.comments.map((comment) => comment.id).indexOf(id)
        if (deletePos !== -1)
            state.comments[deletePos].active = true

        console.log("found el", state.comments[deletePos])
    },
    SET_COMMENT_ANSWER_STATUS: (state, status) => {
        state.answerCommentsStatus = status
    },
    SET_DELETE_COMMENT_STATUS: (state, status) => {
        state.deleteCommentStatus = status
    },
    SET_SUBMIT_ISSUE_STATUS: (state, status) => {
        state.submitIssueStatus = status
    }
}
