export default {
    questions(state) {
        return state.questions.payload
    },
    questionsReceived(state) {
        if (state.questions && state.questions.success)
            return true
        return false
    },
    getAddQuestionStatus(state) {
        return state.addQuestionStatus
    },
    getEditQuestionStatus(state) {
        return state.editQuestionStatus
    },
    getDeleteQuestionStatus(state) {
        return state.deleteQuestionStatus
    },
    getAddQuestionnaireStatus(state) {
        return state.addQuestionnaireStatus
    },
    getEditQuestionnaireStatus(state) {
        return state.editQuestionnaireStatus
    },
    getDeleteQuestionFromQuestionnaireStatus(state) {
        return state.deleteQuestionFromQuestionnaireStatus
    },
    getAddQuestionIntoQuestionnaireStatus(state) {
        return state.addQuestionIntoQuestionnaireStatus
    },
    getDeleteQuestionnaireStatus(state) {
        return state.deleteQuestionnaireStatus
    }
}
