export default {
    questionnaires(state) {
        if (state.questionnaires && state.questionnaires.payload)
            return state.questionnaires.payload
        else []
    },
    getLoadedQuestionnaire(state) {
        if (state.loadedQuestionnaire)
            return state.loadedQuestionnaire.payload

        return null
    },
    legislationChapters(state) {

        return state.legislation.chapters

    },
    legislationChapter: (state) => (chapterInfo) => {
        if (state.legislation.chaptersDataMap.has(chapterInfo.id) && state.legislation.chaptersDataMap.get(chapterInfo.id).has(chapterInfo.subchapter)) {
            return state.legislation.chaptersDataMap.get(chapterInfo.id).get(chapterInfo.subchapter)
        }

        return null
    },
    getQuestionnairesStatisticsScores(state) {
        return state.questionnairesStatisticsScores
    },
    getCommentAskedStatus(state) {
        return state.commentAskedStatus
    },
    comments(state) {
        return state.comments
    },
    fullRetrievedComments(state) {
        return state.fullRetrievedComments
    },
    getAnswerCommentsStatus(state) {
        return state.answerCommentsStatus
    },
    getDeleteCommentStatus(state) {
        return state.deleteCommentStatus
    },
    getSubmitIssueStatus(state) {
        return state.submitIssueStatus
    },
    isQuestionFeatureDisabled(state) {
        return state.disableAskQuestionFeature;
    }
}
