<template>
  <div>
    <Verify/>
  </div>
</template>

<script>

import Verify from "../components/Account/Verify";
export default {
  name: 'VerifyEmail',
  components: {Verify},
  data() {
    return {
    }
  }
}
</script>
