<template>
  <v-dialog v-model="showDialog" class="elevation-0" max-width="1500px">
    <v-card elevation="8" shaped style="overflow: hidden">
      <v-row justify="end">
        <v-col cols="3" md="1" class="justify-end">
          <v-btn fab large @click="showDialog = false" class="my-2">
            <v-icon x-large color="error">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <AdCards :before-summary="beforeSummary" @close-dialog="showDialog = false"/>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>

</template>


<script>
import AdCards from "./AdCards";

export default {
  name: 'Advertisement',
  components: {AdCards},
  props: {
    beforeSummary: {
      default: false
    }
  },
  data() {
    return {
      showDialog: true
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {
    this.showDialog = true
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="sass">

</style>
